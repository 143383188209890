import React, { useState } from 'react'
import '../../assets/theme/theme.less'
import { LayoutComponent as Layout, TableComponent as Table, OperationsBalance, ButtonComponent as Button } from '../../components'
import { Row, Col, Modal, message as alert, DatePicker } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { getOperations, getBalance, createOperation } from '../../redux/slices/operationsSlice'
import moment from 'moment'
import { maskCurrency, generateReport } from '../../utils/functions'
import { useParams } from 'react-router-dom'
import CreateOperations from './createOperations'

const { RangePicker } = DatePicker

const Operations = () => {
    const { type } = useSelector(state => state.login)
    const { isLoading, operations, success, balance, isSubmit, isLoadingBalance } = useSelector(state => state.operations)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { id } = useParams()
    const [modal, setModal] = useState(false)
    const [dateRange, setDateRange] = useState()
    const [error, setError] = useState(false)

    const handleModal = () => {
        const aux = modal
        setModal(!aux)
    }

    const create = (quota, output) => {
        const data = { type: id, quota, output }
        dispatch(createOperation(data)).then((res) => {
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                handleModal()
                alert.success('Operação inserida')
                const info = { type: id }
                dispatch(getOperations(info))
                dispatch(getBalance(info))
            }
        })
    }

    const handleDate = (e) => {
        var start = e[0]['_d']
        var end = e[1]['_d']
        start = moment(start).format("YYYY-MM-DD")
        end = moment(end).format("YYYY-MM-DD")
        var range = []
        range.push(start)
        range.push(end)

        setDateRange(range)
    }

    const handleRangeStatement = (type, filter) => {
        if(dateRange === undefined) {
            setError(true)
        } else {
            const columns = []
            generateReport(columns, type, filter, dateRange)
        }
    }

    useEffect(() => {
        if(type !== 1) {
            return navigate("/")
        } 
        const data = { type: id }
        dispatch(getOperations(data))
        dispatch(getBalance(data))
    }, [dispatch, id, navigate, type])
    
    const columns = [
        {
            title: 'Data',
            dataIndex: 'dataOperacao',
            key: 'dataOperacao',
            align: 'center',
            width: '20%',
            render: data => moment(data).format("DD/MM/YYYY")
        },
        {
            title: 'Total entrada USD',
            dataIndex: 'totalEntradaUsd',
            key: 'totalEntradaUsd',
            align: 'center',
            width: '20%',
            render: value => 'US$'+maskCurrency(value)
        },
        {
            title: 'Total saída USD',
            dataIndex: 'totalSaidaUsd',
            key: 'data',
            align: 'center',
            width: '20%',
            render: value => 'US$'+maskCurrency(value)
        },
        {
            title: 'Total entrada R$',
            dataIndex: 'totalEntradaRs',
            key: 'totalEntradaRs',
            align: 'center',  
            width: '20%',
            render: value => 'R$'+maskCurrency(value)
        },
        {
            title: 'Total saída R$',
            dataIndex: 'totalSaidaRs',
            key: 'totalSaidaRs',
            align: 'center',
            width: '20%',
            render: value => 'R$'+maskCurrency(value)
        },
        {
            title: 'Total clientes Flow.in',
            dataIndex: 'totalClientesFlow',
            key: 'totalClientesFlow',
            align: 'center',
            width: '20%',
            render: value => 'R$'+maskCurrency(value)
        },
        {
            title: 'Lucro obtido USD',
            dataIndex: 'lucroObtidoUsd',
            key: 'lucroObtidoUsd',
            align: 'center',
            width: '20%',
            render: value => 'US$'+maskCurrency(value)
        },
        {
            title: 'Lucro obtido R$',
            dataIndex: 'lucroObtidoRs',
            key: 'lucroObtidoRs',
            align: 'center',
            width: '20%',
            render: value => 'R$'+maskCurrency(value)
        },
        {
            title: 'Cotação',
            dataIndex: 'cotacao',
            key: 'cotacao',
            align: 'center',
            width: '20%',
            render: value => 'R$'+maskCurrency(value)
        },
        {
            title: 'Lucro clientes',
            dataIndex: 'lucroClientes',
            key: 'lucroClientes',
            align: 'center',
            width: '20%',
            render: value => 'R$'+maskCurrency(value)
        },
        {
            title: 'Lucro Flow.in',
            dataIndex: 'lucroFlow',
            key: 'lucroFlow',
            align: 'center',
            width: '20%',
            render: value => 'R$'+maskCurrency(value)
        },
        {
            title: 'Variação',
            dataIndex: 'variacao',
            key: 'variacao',
            align: 'center',
            width: '20%',
            render: value => parseFloat(value*100).toFixed(4)+"%"
        },
        {
            title: 'Variação clientes',
            dataIndex: id === 'asa' ? 'variacaoClientes' : 'variacaoDividida',
            key: id === 'asa' ? 'variacaoClientes' : 'variacaoDividida',
            align: 'center',
            width: '20%',
            render: value => parseFloat(value*100).toFixed(4)+"%"
        },
        {
            title: 'Tipo de operação',
            dataIndex: 'tipoOperacao',
            key: 'tipoOperacao',
            align: 'center',
            width: '20%'
        },
    ]

    return (
        <Layout width>
            <Row>
                <Col md={12}>
                    <h1 className="title page-title">Operações {id === 'asa' ? 'ASA' : 'SPIRIT'}</h1>
                </Col>
                <Col md={6}></Col>
                <Col md={6}>
                    <Button type="primary" active onClick={handleModal}>Nova operação</Button>
                </Col>
                <Col md={24} className="mt-30">
                    {isLoading ? 'Carregando...' : success ?
                        <OperationsBalance balance={balance} type={id} isLoading={isLoadingBalance} /> : 'Houve um erro ao carregar os saldos.'}
                </Col>
                <Col md={24} className="mt-50">
                    <Row gutter={15}>
                        <Col md={6}>
                            <RangePicker onChange={handleDate} format="DD/MM/YYYY" />
                            {error && <small className='tx-red font-bold'>Selecione um intervalo de data</small>}
                        </Col>
                        <Col md={6}>
                            <Button type="action" onClick={() => handleRangeStatement(id, 'range')}>Exportar relatório</Button>
                        </Col>
                        <Col md={6}></Col>
                        <Col md={6}>
                            <Button type="action" onClick={() => generateReport([], id, 'full', dateRange)}>Relatório completo</Button>
                        </Col>
                    </Row>
                </Col>
                <Col md={24} className="mt-30" id="operations-table">
                    <Table column={columns} data={operations} loading={isLoading} /> 
                </Col>
            </Row> 
            <Modal
                visible={modal}
                centered
                footer={null}
                closable={false}
                maskClosable={false}
                className="modal-deny"
                width="50vw"
            >
                <CreateOperations handleModal={handleModal} isSubmit={isSubmit} create={create} />
            </Modal>
        </Layout>
    )
}

export default Operations