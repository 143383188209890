import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../api/config"

const initialState = {
    isLoading: false,
    isSubmit: false,
    success: false,
    message: null,
    conversions: [],
    tokens: [],
    successAction: false,
    anualFee: null,
    isLoadingFee: false
}

export const getConversions = createAsyncThunk(
    'wallet/',
    async (thunkAPI) => {
        try {
            const res = await request('wallet/conversions')
            if(res.code === 51) {
                return res.conversoes
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao retornar as conversões')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve  um erro ao retornar as conversões')
        }
    }
)

export const getTokens = createAsyncThunk(
    'wallet/tokens',
    async (thunkAPI) => {
        try {
            const res = await request('wallet/requested-tokens')
            if(res.code === 200) {
                return res.vendas
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao retornar os tokens pendentes')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao retornar os tokens pendentes')
        }
    }
)

export const handleToken = createAsyncThunk(
    'wallet/approve-token',
    async (values, thunkAPI) => {
        try {   
            const response = await request(`wallet/approve-tokens/${values.id}`, "PUT", {
                vendaConfirmada: values.confirmed,
                idCliente: values.idClient
            })
            if(response.code === 200) {
                return response
            } else {
                return thunkAPI.rejectWithValue(`Houve um erro ao ${values.confirmed === 1 ? 'aprovar' : 'reprovar'} a compra do token`)
            }
        } catch(e) {
            return thunkAPI.rejectWithValue(`Houve um erro ao ${values.confirmed === 1 ? 'aprovar' : 'reprovar'} a compra do token`)
        }
    }
)

export const getFee = createAsyncThunk(
    'wallet/yield-fee',
    async (thunkAPI) => {
        try {
            const response = await request('wallet/yield-fee')
            if(response.code === 200) {
                return response.fee
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao retornar a taxa anual do yield')
        }
    }
)

export const changeFee = createAsyncThunk(
    'wallet/change-fee',
    async (values, thunkAPI) => {
        try {
            const response = await request("wallet/change-yield-fee", "POST", {
                fee: values.fee
            })
            return response
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao alterar a taxa anual do yield')
        }
    }
)

const walletSlice = createSlice({
    name: 'wallet',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getConversions.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getConversions.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true
                state.conversions = payload
            })
            .addCase(getConversions.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
            .addCase(getTokens.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getTokens.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true
                state.tokens = payload
            })
            .addCase(getTokens.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
            .addCase(handleToken.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(handleToken.fulfilled, (state, { payload }) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(handleToken.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.successAction = false
                state.message = payload
            })
            .addCase(getFee.pending, (state) => {
                state.isLoadingFee = true
            })
            .addCase(getFee.fulfilled, (state, { payload }) => {
                state.isLoadingFee = false
                state.success = true
                state.anualFee = payload
            })
            .addCase(getFee.rejected, (state, { payload }) => {
                state.isLoadingFee = false
                state.success = false
                state.message = payload
            })
            .addCase(changeFee.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(changeFee.fulfilled, (state, { payload }) => {
                state.isSubmit = false
                state.success = true
            })
            .addCase(changeFee.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.success = false
            })
    }
})

const { reducer } = walletSlice

export default reducer