import React, { useState } from 'react'
import '../../assets/theme/theme.less'
import './users.less'
import { LayoutComponent as Layout, TabsMenuComponent as TabsMenu, ButtonComponent as Button, ButtonTabsComponent as ButtonTab } from '../../components'
import UsersTable from './usersTable'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getUsers } from '../../redux/slices/usersSlice'

const Users = () => {
    const dispatch = useDispatch()
    const { isLoading, users } = useSelector(state => state.users)
    const [filteredUsers, setFilteredUsers] = useState([...users])
    const [filterActive, setFilterActive] = useState(4)

    const filter = (type) => {
        const copy = [...users]
        var aux
        const status = [0,4,5]
        switch(type) {
            case 1:
                aux = copy.filter(user => user.Contratos.length === 0 || user.Contratos.filter(c => status.indexOf(c.status) === -1).length === 0)
                break
            case 2:
                aux = copy.filter(user => user.Contratos.length > 0)
                aux = aux.filter(user => user.Contratos.filter(c => c.tipoContrato !== 6).length > 0)
                aux = aux.filter(user => user.Contratos.filter(c => status.indexOf(c.status) === -1))
                break
            case 3:
                aux = copy.filter(user => user.Contratos.length > 0)
                aux = aux.filter(user => user.Contratos.filter(c => c.tipoContrato === 6).length > 0)
                aux = aux.filter(user => user.Contratos.filter(c => status.indexOf(c.status) === -1))
                setFilteredUsers(aux)
                break
            default:
                aux = copy
        }
        setFilterActive(type)
        setFilteredUsers(aux)
    }

    useEffect(() => {
        dispatch(getUsers())
        setFilterActive(4)
    }, [dispatch])
    
    return (
        <Layout width>
            <Row>
                <Col md={24}>
                    <h1 className="title page-title">Clientes</h1>
                </Col>
                <Col md={24} className="mt-15 d-flex align-center">
                    <div className="d-flex align-center m-auto">
                        <TabsMenu type="clients" />
                        <Link to="/clientes/relatorio"><ButtonTab text="Relatório" active={false} notification={undefined} /></Link>
                    </div>
                </Col>
                <Col md={24}>
                    <div className="filter-wrapper">
                        <Button type="filter" className={filterActive === 1 && `btn-filter-active`} onClick={() => filter(1)}>Sem Contrato</Button>
                        <Button type="filter" className={filterActive === 2 && `btn-filter-active`} onClick={() => filter(2)}>ASA</Button>
                        <Button type="filter" className={filterActive === 3 && `btn-filter-active`} onClick={() => filter(3)}>Spirit</Button>
                        <Button type="filter" className={filterActive === 4 && `btn-filter-active`} onClick={() => filter(4)}>Todos</Button>
                    </div>
                </Col>
                <Col className="mt-25" md={24} id="users-table">
                    <UsersTable loading={isLoading} users={filteredUsers.length > 0 ? filteredUsers : users} />
                </Col>
            </Row>
        </Layout>
    )
}

export default Users