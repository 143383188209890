import React from 'react'
import { Card } from 'antd'
import moment from 'moment'
import { Link } from 'react-router-dom'

const CardContrato = (props) => {

    const { contract, active } = props

    return (
        <Link to={`/contrato/${contract.id}`}>
            <Card className={`contract-card ${!active ? 'contract-card-inactive' : ''}`}>
                <h3 className="tx-pink font-bold">{contract.tipoContrato === 6 ? 'SPIRIT' : 'ASA '} {contract.tipoContrato !== 6 && `| ${contract.duracao} meses`}</h3>
                <label className="tx-pink font-bold">ID <span className="tx-gray font-bold ml-5">#{contract.id}</span></label><br />
                <label className="tx-pink font-bold mt-10">Vencimento</label><br />
                <span className="tx-gray font-16">{contract.tipoContrato === 6 ? 'Indeterminado' : moment(contract.dataFim).format("DD/MM/YYYY")}</span>
            </Card>
        </Link>
    )
}

export default CardContrato