import { Row, Col, Input, message as alert } from 'antd'
import { ButtonComponent as Button } from '../../components'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { validateStatement } from '../../redux/slices/contractsSlice'
import { LoadingOutlined } from '@ant-design/icons';

const ValidateStatement = (props) => {
    const { handleModal, contract } = props
    const { isSubmit } = useSelector(state => state.contracts)
    const [value, setValue] = useState()
    const [error, setError] = useState(false)
    const dispatch = useDispatch()

    const handleValue = (e) => {
        setValue(e.target.value)
    }

    const verifyValues = () => {
        if(value === undefined) {
            setError(true)
        } else {
            onSubmit()
        }
    }

    const onSubmit = () => {
        const data = { idContract: contract.id, hash: value }
        dispatch(validateStatement(data)).then((res) => {
            console.log(res)
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                setValue()
                handleModal()
                alert.success('Extrato validado')
            }
        })
    }

    return (
        <>
        <Row>
            <Col md={20} className="d-flex align-center">
                <h1 className="font-bold font-28 tx-white">Autenticar Extrato</h1>                
            </Col>
            <Col md={4} className="d-flex align-center justify-end">
                <label className="tx-white btn-close" onClick={handleModal}>Cancelar</label>
            </Col>
        </Row>
        <Row className="deny-info-wrapper d-flex align-items-center">
            <Col md={24} className="pl-20">
                <Row>
                    <Col md={20}>
                        <h3 className="tx-pink font-bold">{contract.Cliente.nome}</h3>
                    </Col>
                    <Col md={4}>
                        <label className="tx-pink font-bold">{contract.tipoContrato == 6 ? "SPIRIT" : "ASA"}</label><br />
                        <label className="tx-gray font-16">#{contract.id}</label>
                    </Col>
                    <Col md={24}>
                        <hr />
                    </Col>
                </Row>
                <label className="tx-pink font-bold font-16 mt-30">Hash de autenticação</label>
                <Input className="mt-15 bg-white" value={value} onChange={handleValue} disabled={isSubmit} />
                {error && <small className="tx-red">Digite um hash válido</small>}
                <Row className="mt-15">
                    <Col md={24}>
                        <Button onClick={verifyValues} type='primary' active>{isSubmit ? <LoadingOutlined /> : 'Autenticar'}</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
    )
}

export default ValidateStatement