import React, { useEffect, useState } from 'react'
import '../../assets/theme/theme.less'
import { LayoutComponent as Layout, TitleTabs as Title } from '../../components'
import { Row, Col, Tabs, List, message as alert } from 'antd'
import CardContract from './cardContract'
import { getContracts } from '../../redux/slices/contractsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { plans } from '../../utils/const'
import { maskCurrency } from '../../utils/functions'
import { requestRenewal } from '../../redux/slices/contractsSlice'

const { TabPane } = Tabs

const ExpiredContracts = () => {
    const dispatch = useDispatch()
    const [clicked, setClicked] = useState(0)
    const [expiredContracts, setExpiredContracts] = useState([])
    const [totalWithdrawl, setTotalWithdrawl] = useState([])
    const [totalRenewal, setTotalRenewal] = useState([])
    const [renewalWithdrawl, setRenewalWithdrawl] = useState([])
    const [renewalContribuition, setRenewalContribuition] = useState([])
    const [error, setError] = useState(null)
    const { isLoading, message, contracts, isSubmit, successAction } = useSelector(state => state.contracts)
    
    const handleContract = (idContract, idClient, type, months, amount, finalAmount) => {
        setClicked(idContract)
        setError(null)
        
        if(type === 0) {
            setError("Selecione um tipo de renovação")
            return
        }
        if(months === 0) {
            setError("Selecione um tipo de contrato para renovação")
            return
        }
        if((type === 11 || type === 10) && amount === 0) {
            setError("Insira um valor válido")
            return
        }

        var renewalAmount = parseFloat(finalAmount)
        if(type === 11) {
            renewalAmount = parseFloat(amount) + renewalAmount
        }
        if(type === 10) {
            renewalAmount = renewalAmount - parseFloat(amount)
        }
        if(type !== 4) {
            if(renewalAmount >= plans[months]) {
                const data = { idContract, idClient, value: renewalAmount, status: type, contractType: months }
                dispatch(requestRenewal(data))
            } else {
                var diff = plans[months] - renewalAmount
                setError(`Não é possível renovar este contrato. O valor de renovação está R$${maskCurrency(diff)} abaixo do necessário para o tipo de contrato selecionado.`)
            }
        } else {
            const data = { idContract, idClient, value: renewalAmount, status: type, contractType: months }
            dispatch(requestRenewal(data))
        }
    }

    useEffect(() => {
        if(message !== null) {
            alert.error(message)
        } else {
            const data = { status: 12 }
            dispatch(getContracts(data))
        }
    }, [message, successAction, dispatch])

    useEffect(() => {
        const data = { status: 12 }
        dispatch(getContracts(data))
    }, [dispatch])

    useEffect(() => {
        if(!isLoading && contracts) {
            setExpiredContracts(contracts.filter(contract => contract.status === 12))
            setTotalWithdrawl(contracts.filter(contract => contract.status === 8))
            setTotalRenewal(contracts.filter(contract => contract.status === 9))
            setRenewalWithdrawl(contracts.filter(contract => contract.status === 10))
            setRenewalContribuition(contracts.filter(contract => contract.status === 11))
        }
    }, [contracts, isLoading])

    return (
        <Layout width>
            <Row>
                <Col md={24}>
                    <h1 className="title page-title">Vencidos</h1>
                </Col>
            </Row>
            <Row className="mt-15" gutter={[15,15]}>
                <Col md={24}>
                    <Tabs defaultActiveKey="1" centered>
                        <TabPane tab={<Title title="Contratos Vencidos" notification={expiredContracts.length} />} key="1">
                            <Row className="mt-15">
                                {isLoading ? 'Carregando':
                                    <List
                                        grid={{ gutter: 16, column: 3}}
                                        dataSource={expiredContracts}
                                        pagination
                                        renderItem={item => (
                                            <List.Item>
                                                <CardContract contract={item} handleContract={handleContract} clicked={clicked} error={error} isSubmit={isSubmit} />
                                            </List.Item>
                                        )}
                                    /> }
                            </Row>
                        </TabPane>
                        <TabPane tab={<Title title="Resgate total" notification={totalWithdrawl.length} />} key="2">
                            <Row className="mt-15">
                                {isLoading ? 'Carregando' : 
                                    <List
                                        grid={{ gutter: 16, column: 3}}
                                        dataSource={totalWithdrawl}
                                        pagination
                                        renderItem={item => (
                                            <List.Item>
                                                <CardContract contract={item} type="total_withdraw" handleContract={handleContract} clicked={clicked} error={error} isSubmit={isSubmit} />
                                            </List.Item>
                                        )}
                                    /> }
                            </Row>
                        </TabPane>
                        <TabPane tab={<Title title="Renovação total" notification={1} />} key="3">
                            <Row className="mt-15">
                                {isLoading ? 'Carregando' : 
                                    <List
                                        grid={{ gutter: 16, column: 3}}
                                        dataSource={totalRenewal}
                                        pagination
                                        renderItem={item => (
                                            <List.Item>
                                                <CardContract contract={item} type="total_renewal" handleContract={handleContract} clicked={clicked} error={error} isSubmit={isSubmit} />
                                            </List.Item>
                                        )}
                                    /> }   
                            </Row>
                        </TabPane>
                        <TabPane tab={<Title title="Renovação com aporte" notification={renewalContribuition.length} />} key="4">
                            <Row className="mt-15">
                                {isLoading ? 'Carregando' : 
                                    <List
                                        grid={{ gutter: 16, column: 3}}
                                        dataSource={renewalContribuition}
                                        pagination
                                        renderItem={item => (
                                            <List.Item>
                                                <CardContract contract={item} type="renewal_contribution" handleContract={handleContract} clicked={clicked} error={error} isSubmit={isSubmit} />
                                            </List.Item>
                                        )}
                                    /> }
                            </Row>
                        </TabPane>
                        <TabPane tab={<Title title="Renovação com resgate" notification={renewalWithdrawl.length} />} key="5">
                            <Row className="mt-15">
                                {isLoading ? 'Carregando' : 
                                    <List
                                        grid={{ gutter: 16, column: 3}}
                                        dataSource={renewalWithdrawl}
                                        pagination
                                        renderItem={item => (
                                            <List.Item>
                                                <CardContract contract={item} type="renewal_withdraw" handleContract={handleContract} clicked={clicked} error={error} isSubmit={isSubmit} />
                                            </List.Item>
                                        )}
                                    /> }
                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
            
        </Layout>
    )
}

export default ExpiredContracts