import { useState } from 'react';
import { Row, Col, Input } from 'antd'
import { ButtonComponent as Button } from '../../components'
import { LoadingOutlined } from '@ant-design/icons';

const CreateOperations = (props) => {
    const { handleModal, isSubmit, create } = props
    const [output, setOutput] = useState(0)
    const [quota, setQuota] = useState(0)
    const [error, setError] = useState()

    const handleChange = (value, type) => {
        if(type === 'quota') {
            setQuota(value)
        } else {
            setOutput(value)
        }
    }

    const checkValues = () => {
        if(quota === 0 || quota < 0) {
            var aux = {}
            aux.msg = 'A cotação deve ser maior que 0.'
            aux.type = 'quota'
            return setError(aux)  
        }
        if(quota === '') {
            var aux2 = {}
            aux2.msg = 'Este campo é obrigatório'
            aux2.type = 'quota'
            return setError(aux2)
        }        
        create(quota, output)
    }

    return (
        <>
        <Row>
            <Col md={20} className="d-flex align-center">
                <h1 className="font-bold font-28 tx-white">Nova operação</h1>                
            </Col>
            <Col md={4} className="d-flex align-center justify-end">
                <label className="tx-white btn-close" onClick={handleModal}>Cancelar</label>
            </Col>
        </Row>
        <Row className="deny-info-wrapper d-flex align-items-center">
            <Col md={24} className="pl-20">
                <label className="tx-pink font-bold font-16">Valor de saída</label>
                <Input className="mt-15 bg-white" type="number" value={output} onChange={(e) => handleChange(e.target.value, 'output')} />
                {error?.type === 'output' && <small className='tx-red font-bold'>{error.msg}</small>}<br />
                <label className="tx-pink font-bold font-16 mt-15">Cotação</label>
                <Input className="mt-15 bg-white" type="number" value={quota} onChange={(e) => handleChange(e.target.value, 'quota')} />
                {error?.type === 'quota' && <small className='tx-red font-bold'>{error.msg}</small>}
                <Row className="mt-15">
                    <Col md={24}>
                        <Button disabled={isSubmit} onClick={checkValues} type='primary' active>{isSubmit ? <LoadingOutlined /> : 'Inserir operação'}</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
    )
}

export default CreateOperations