import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { request, getInfoLocalStorage } from "../api/config"

const stateFromLocalStorage = getInfoLocalStorage() 

const initialState = {
    isLoading: false,
    success: false,
    message: null,
    contributions: [],
    isSubmit: false,
    successAction: false
}

export const getContributions = createAsyncThunk(
    'contributions/',
    async (thunkAPI) => {
        try {
            const res = await request('aportes/getAportesPendentes')
            if(res.code === 51) {
                return ({ contributions: res.aportes })
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao retornar os aportes')
        }
    }
)

export const denyContribution = createAsyncThunk(
    'contributions/deny',
    async (values, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const res = await request('aportes/denyAporte', 'PUT', {
                motivo: values.reason,
                aporte: values.idContribution,
                adminId: id
            })
            if(res.code === 51) {
                return res
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ano reprovar o aporte')
        }
    }
)

export const approveContribution = createAsyncThunk(
    'contributions/approve',
    async (values, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const res = await request('aportes/confirmAporte', 'PUT', {
                aporte: values.idContribution,
                cotacao: values.quota,
                adminId: id
            })
            console.log(res)
            if(res.code === 51) {
                return res
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            console.log(e)
            return thunkAPI.rejectWithValue('Houve um erro ao aprovar o aporte')
        }
    }
)

export const approveSpiritContribution = createAsyncThunk(
    'contributions/approveSpirit',
    async (values, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const res = await request('aportes/confirmAporteSpirit', 'PUT', {
                aporte: values.idContribution,
                adminId: id,
                idContrato: values.idContract
            })
            if(res.code === 51) {
                return res
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao aprovar o aporte')
        }
    }
)

const contributionsSlice = createSlice({
    name: 'contributions',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getContributions.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getContributions.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true
                state.contributions = payload.contributions
            })
            .addCase(getContributions.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
            .addCase(denyContribution.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(denyContribution.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(denyContribution.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.successAction = false
                state.message = payload
            })
            .addCase(approveContribution.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(approveContribution.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(approveContribution.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.successAction = false
                state.message = payload
            })
            .addCase(approveSpiritContribution.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(approveSpiritContribution.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(approveSpiritContribution.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.successAction = false
                state.message = payload
            })
    }
})

const { reducer } = contributionsSlice

export default reducer