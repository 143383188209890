import { Row, Col, Input } from 'antd'
import moment from 'moment'
import { ButtonComponent as Button } from '../../components'
import { useState } from 'react'

const ApproveContribution = (props) => {
    const { contribution, handleModalApprove, approve } = props
    const [quota, setQuota] = useState(0)
    const [error, setError] = useState(false)

    const handleQuota = (e) => {
        setQuota(e.target.value)
    }

    const approveContribution = () => {
        const { contribution } = props
        if(quota === 0 || quota < 0 || quota === '') {
            setError(true)
        } else {
            handleModalApprove()
            approve(quota, contribution.id, true)
        }
        
    }

    return (
        <>
        <Row>
            <Col md={20} className="d-flex align-center">
                <h1 className="font-bold font-28 tx-white">{contribution.Contrato.tipoContrato === 6 ? 'Cotizar aporte' : 'Aprovar aporte'}</h1>                
            </Col>
            <Col md={4} className="d-flex align-center justify-end">
                <label className="tx-white btn-close" onClick={handleModalApprove}>Cancelar</label>
            </Col>
        </Row>
        <Row className="deny-info-wrapper d-flex align-items-center">
            <Col md={12} className="border">
                <Row>
                    <Col md={20}>
                        <h3 className="tx-pink font-bold">{contribution.Contrato.Cliente.nome}</h3>
                    </Col>
                    <Col md={4}>
                        <label className="tx-pink font-bold">{contribution.Contrato.tipoContrato == 6 ? "SPIRIT" : "ASA"}</label><br />
                        <label className="tx-gray font-16">#{contribution.Contrato.id}</label>
                    </Col>
                    <Col md={24}>
                        <hr />
                    </Col>
                    <Col md={12} className="mt-10">
                        <label className="tx-pink font-bold">Solicitação</label><br />
                        <label className="tx-gray">{moment(contribution.dataSolicitacao).format("DD/MM/YYYY")}</label>
                    </Col>
                    <Col md={24} className="mt-10">
                        <hr />
                    </Col>
                    <Col md={24} className="mt-10">
                        <label className="tx-pink font-bold">Valor</label><br />
                        <label className="tx-gray font-bold font-22">R$ {contribution.valor}</label>
                    </Col>
                </Row>
            </Col>
            <Col md={12} className="pl-20">
                <label className="tx-pink font-bold font-16">Valor da cotação</label>
                <Input className="mt-15 bg-white" value={quota} onChange={handleQuota} type="number" />
                {error && <small className='tx-red font-bold'>A cotação deve ser maior que 0</small>}
                <Row className="mt-15">
                    <Col md={24}>
                        <Button type={contribution.Contrato.tipoContrato === 6 ? 'primary' : 'success'} active={contribution.Contrato.tipoContrato === 6 && true} onClick={approveContribution}>{contribution.Contrato.tipoContrato === 6 ? 'Inserir cotação' : 'Aprovar'}</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
    )
}

export default ApproveContribution