import React, { useEffect, useState } from 'react'
import '../../assets/theme/theme.less'
import { LayoutComponent as Layout, ButtonComponent as Button } from '../../components'
import { Row, Col, Upload, Modal, message as alert, DatePicker } from 'antd'
import { InnerSideBar } from '../../components'
import ContractTable from './contractTable'
import StatementTable from './statementTable'
import { ReactComponent as iconUpload } from '../../assets/img/upload.svg'
import Icon from '@ant-design/icons';
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getContract, uploadContract } from '../../redux/slices/contractsSlice'
import moment from 'moment'
import { maskCurrency, downloadFile, generateStatement } from '../../utils/functions'
import CreateOperation from './createOperation'
import { LoadingOutlined } from '@ant-design/icons';
import ValidateStatement from './validateStatement'

const { Dragger } = Upload
const { RangePicker } = DatePicker

const ContractDetails = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { isLoading, success, contract, isUpload } = useSelector(state => state.contracts)
    const [contributions, setContributions] = useState([])
    const [withdrawls, setWithdrawls] = useState([])
    const [statement, setStatement] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [openModalStatement, setOpenModalStatement] = useState(false)
    const [modalType, setModalType] = useState('contribution')
    const [file, setFile] = useState(null)
    const [dateRange, setDateRange] = useState()
    const [error, setError] = useState()

    const handleModal = (type) => {
        var aux = openModal
        setOpenModal(!aux)
        setModalType(type)
    }

    const handleModalStatement = () => {
        var aux = openModalStatement
        setOpenModalStatement(!aux)
    }

    const beforeUpload = file => {
        setFile(file)
        uploadFile(file)
    }

    const uploadFile = (file) => {
        const data = { idContract: id, type: 5, contractType: contract.tipoContrato === 6 ? 'Spirit' : 'Asa', idClient: contract.Cliente.id, file }
        dispatch(uploadContract(data)).then((res) => {
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                alert.success('Contrato enviado')
                const data = { idContract: id }
                dispatch(getContract(data))
            }
        })
    }

    const handleDate = (e) => {
        var start = e[0]['_d']
        var end = e[1]['_d']
        start = moment(start).format("YYYY-MM-DD")
        end = moment(end).format("YYYY-MM-DD")
        var range = []
        range.push(start)
        range.push(end)

        setDateRange(range)
    }

    const handleRangeStatement = () => {
        if(dateRange === undefined) {
            setError(true)
        } else {
            generateStatement('range', 'extrato', dateRange, contract)
        }
    }

    useEffect(() => {
        const data = { idContract: id }
        dispatch(getContract(data))
    }, [dispatch, id])

    useEffect(() => {
        if(contract?.Aportes !== undefined) {
            const auxContributions = contract.Aportes.filter(item => item.status === 1 || item.status === 3)
            const auxWithdrawls = contract.Resgates.filter(item => item.status === 3)
            const auxStatement = contract.tipoContrato == 6 ? contract.OperacaoClientesSpirits : contract.OperacaoClientes
            setStatement(auxStatement)
            setContributions(auxContributions)
            setWithdrawls(auxWithdrawls)
        }
        
    }, [contract])

    console.log("contract",contract);

    return (
        <Layout width>
            <Row>
            {isLoading ? 'Carregando...' : !success ? 'Houve um erro ao recuperar os dados do contrato' :
                <Col md={18}>
                    <h1 className="title page-title">Detalhes do Contrato</h1>
                    <Row gutter={[15,15]} className="mt-15">
                        <Col md={16} className="mt-15">
                            <div className="user-info-wrapper">
                                <div className="d-flex w-100 justify-between">
                                    <div>
                                        <label className="tx-pink font-bold">
                                            Nome
                                        </label><br />
                                        <label className="tx-gray font-16">
                                            {contract.Cliente.nome}
                                        </label>
                                    </div>
                                    <div>
                                        <label className="tx-pink font-bold">
                                            Produto
                                        </label><br />
                                        <label className="tx-gray font-16">
                                            {contract.tipoContrato === 6 ? 'SPIRIT' : `ASA | ${contract.duracao} meses`}
                                        </label>
                                    </div>
                                </div>
                                <div className="d-flex w-100 justify-between mt-25">
                                    <div>
                                        <label className="tx-pink font-bold">
                                            Data Início
                                        </label><br />
                                        <label className="tx-gray font-16">
                                            {moment(contract.dataInicio).format("DD/MM/YYYY")}
                                        </label>
                                    </div>
                                    <div>
                                        <label className="tx-pink font-bold">
                                            Data Fim
                                        </label><br />
                                        <label className="tx-gray font-16">
                                            {contract.tipoContrato === 6 ? 'Indeterminado' : moment(contract.dataFim).format("DD/MM/YYYY")}
                                        </label>
                                    </div>
                                    <div>
                                        <label className="tx-pink font-bold">
                                            ID
                                        </label><br />
                                        <label className="tx-gray font-16">
                                            #{contract.id}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={8} className="mt-15">
                            <div className="user-info-wrapper">
                                <label className="tx-pink font-bold">
                                    Saldo Contrato
                                </label>
                                <h2 className="tx-gray font-bold font-28">
                                    R${ contract.ValorContratos.length > 0 ? maskCurrency(contract.ValorContratos[0].valorDia) : "0,00"}
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[15,15]} className="mt-25">
                        <Col md={6} className="mt-25">
                            <label className="tx-placeholder font-bold">Aportes Realizados ({contributions.length})</label>
                        </Col>
                        <Col md={18} className="mt-25">
                            <hr className="profile-divisor" />
                        </Col>
                        <Col md={16} className="mt-25">
                            <ContractTable data={contributions} type="contribution" />
                        </Col>
                        <Col md={8} className="mt-25">
                            <div className="user-info-wrapper">
                                <label className="tx-pink font-bold">
                                    Valor Total de Aportes
                                </label>
                                <h2 className="tx-gray font-bold font-28">
                                    R${maskCurrency(contributions.reduce((n, {valor}) => n + valor, 0))}
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[15,15]} className="mt-25">
                        <Col md={6} className="mt-25">
                            <label className="tx-placeholder font-bold">Resgates Realizados ({withdrawls.length})</label>
                        </Col>
                        <Col md={18} className="mt-25">
                            <hr className="profile-divisor" />
                        </Col>
                        <Col md={16} className="mt-25">
                            <ContractTable type="withdraw" data={withdrawls} />
                        </Col>
                        <Col md={8} className="mt-25">
                            <div className="user-info-wrapper">
                                <label className="tx-pink font-bold">
                                    Valor Total de Resgates
                                </label>
                                <h2 className="tx-gray font-bold font-28">
                                    R${maskCurrency(withdrawls.reduce((n, {valor}) => n + valor, 0))}
                                </h2>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[15,15]} className="mt-25">
                        <Col md={6} className="mt-25">
                            <label className="tx-placeholder font-bold">Extrato Completo</label>
                        </Col>
                        <Col md={18} className="mt-25">
                            <hr className="profile-divisor" />
                        </Col>
                        <Col md={24}>
                            <Row gutter={15}>
                                <Col md={6}>
                                    <RangePicker onChange={handleDate} format="DD/MM/YYYY" />
                                    {error && <small className='tx-red font-bold'>Selcione um intervalo de data</small>}
                                </Col>
                                <Col md={6}>
                                    <Button type="action" onClick={handleRangeStatement}>Gerar Extrato</Button>
                                </Col>
                                <Col md={6}>
                                    <Button type="action" onClick={() => generateStatement('full', 'extrato', dateRange, contract)}>Extrato Completo</Button>
                                </Col>
                                <Col md={6}>
                                    <Button type="action" onClick={() => handleModalStatement()}>Autenticar Extrato</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col md={24} className="mt-25">
                            <StatementTable data={statement} />
                        </Col>
                    </Row>
                    <Row gutter={[15,15]} className="mt-25">
                        <Col md={6} className="mt-25">
                            <label className="tx-placeholder font-bold">Contrato</label>
                        </Col>
                        <Col md={18} className="mt-25">
                            <hr className="profile-divisor" />
                        </Col>
                        <Col md={24} className="mt-25">
                            {contract.arquivo !== null ? 
                                <Button onClick={() => downloadFile(contract.arquivo)} className="w-50" active icon={iconUpload} type="primary">Download contrato</Button>
                            :
                            <Dragger beforeUpload={beforeUpload} onRemove={() => setFile(null)} height={100}>
                                <div className="d-flex justify-center align-center">
                                    {
                                        isUpload ? <LoadingOutlined /> :
                                    <div className='d-flex'>
                                        <Icon component={iconUpload} className="font-22" />
                                        <label className="tx-gray font-bold ml-5">Clique ou arraste o arquivo aqui</label>
                                    </div> }
                                </div>
                            </Dragger> }
                        </Col>
                    </Row>
                </Col>}
                <Col md={6}>
                    <InnerSideBar menu={false} buttons handleModal={handleModal} />
                </Col>
            </Row>
            <Modal
                visible={openModal}
                centered
                footer={null}
                closable={false}
                maskClosable={false}
                className="modal-deny"
                width="50vw"
            >
                <CreateOperation contract={contract} type={modalType} handleModal={handleModal} />
            </Modal>
            <Modal
                visible={openModalStatement}
                centered
                footer={null}
                closable={false}
                maskClosable={false}
                className="modal-deny"
                width="50vw"
            >
                <ValidateStatement contract={contract} handleModal={handleModalStatement} />
            </Modal>    
        </Layout>
    )
}

export default ContractDetails