import React, { useState } from 'react'
import '../../assets/theme/theme.less'
import { LayoutComponent as Layout, TabsMenuComponent as TabsMenu, ButtonTabsComponent as ButtonTab, TitleTabs as Title } from '../../components'
import { Row, Col, Tabs, List, message as alert } from 'antd'
import CardUserKyc from './cardUserKyc'
import CardUserAccess from './cardUserAccess'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getUsers } from '../../redux/slices/usersSlice'
import { confirmAccess, confirmDocument } from '../../redux/slices/usersSlice'

const { TabPane } = Tabs

const UsersPending = () => {
    const { isLoading, success, users } = useSelector(state => state.users)
    const [usersAccess, setUsersAccess] = useState([])
    const [usersKyc, setUsersKyc] = useState([])
    const dispatch = useDispatch() 
    const [clickedCard, setClickedCard] = useState(0)
    const [approvedState, setApproved] = useState(false)
    const [documentTypeState, setDocumentType] = useState(1)

    const confirmUserAccess = async (userId) => {
        setClickedCard(userId)
        dispatch(confirmAccess(userId)).then((res) => {
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                setTimeout(() => {
                    dispatch(getUsers())
                }, 500)
            }
        })
    }

    const confirmUserDocument = async (userId, documentType, approved, personType) => {
        setClickedCard(userId)
        setApproved(approved)
        setDocumentType(documentType)
        const data = { userId, documentType, approved, personType }
        dispatch(confirmDocument(data)).then((res) => {
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                setTimeout(() => {
                    setClickedCard(0)
                    dispatch(getUsers())
                }, 500)
            }
        })
    }

    useEffect(() => {
        dispatch(getUsers())
    }, [dispatch])

    useEffect(() => {
        var copy = users
        var access = copy.filter(user => user.permissao === 4 || user.permissao === 5)
        var kyc = copy.filter(user => user.nivelVerificacao !== "111")
        var firstDoc = kyc.filter(user => user.nivelVerificacao === "001" && (user.documento !== null || user.comprovanteEndereco !== null))
        firstDoc = firstDoc.filter(user => user.documento !== '' || user.comprovanteEndereco !== '')
        console.log(firstDoc)
        var otherDocs = kyc.filter(user => user.nivelVerificacao === "101" || user.nivelVerificacao === "011")
        kyc = firstDoc.concat(otherDocs)
        setUsersAccess([...access])
        setUsersKyc([...kyc])
    }, [users])
    
    return (
        <Layout width>
            <Row>
                <Col md={24}>
                    <h1 className="title page-title">Clientes</h1>
                </Col>
                <Col md={24} className="mt-15 d-flex align-center">
                    <div className="d-flex align-center m-auto">
                        <TabsMenu type="clients" />
                        <Link to="/clientes/relatorio"><ButtonTab text="Relatório" active={false} notification={false} /></Link>
                    </div>
                </Col>
                <Col md={24} className="mt-15">
                    <Tabs defaultActiveKey="1" centered>
                        <TabPane tab={<Title title="Acesso" notification={usersAccess.length} />} key="1">
                            <Row className="mt-15">
                                {isLoading ? 'Carregando' : success ?
                                <List 
                                    grid={{ gutter: 16, column: 3}}
                                    dataSource={usersAccess}
                                    pagination
                                    renderItem={item => (
                                        <List.Item>
                                            <CardUserAccess clickedCard={clickedCard} confirmAccess={confirmUserAccess} user={item} />
                                        </List.Item>
                                    )}
                                /> : 'Houve um erro ao carregar os usuários'}
                            </Row>
                        </TabPane>
                        <TabPane tab={<Title title="KYC" notification={usersKyc.length} />} key="2">
                            <Row>
                                {isLoading ? 'Carregando' : success ? 
                                <List 
                                    grid={{ gutter: 16, column: 3 }}
                                    dataSource={usersKyc}
                                    pagination
                                    renderItem={item => (
                                        <List.Item>
                                            <CardUserKyc user={item} confirmDocument={confirmUserDocument} approved={approvedState} documentType={documentTypeState} clickedCard={clickedCard} />
                                        </List.Item>
                                    )}
                                /> : 'Houve um erro ao carregar os usuários'}
                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </Layout>
    )
}

export default UsersPending