import { TableComponent as Table } from '../../components'
import moment from 'moment';
import { maskCurrency } from '../../utils/functions';

const StatementTable = (props) => {
    const { data } = props
    
    const columns = [
        {
            title: 'Data',
            dataIndex: 'dataOperacao',
            key: 'dataOperacao',
            align: 'center',
            render: date => moment(date).format('DD/MM/YYYY')
        },
        {
            title: 'Total Flow (R$)',
            dataIndex: 'totalFlow',
            key: 'totalFlow',
            align: 'center',
            render: value => 'R$'+maskCurrency(value)
        },
        {
            title: 'Lucro Cliente (R$)',
            dataIndex: 'lucroCliente',
            key: 'lucroCliente',
            align: 'center',
            render: value => 'R$'+maskCurrency(value)
        },
        {
            title: 'RDN',
            dataIndex: 'rentabilidadeDiariaNormal',
            key: 'rentabilidadeDiariaNormal',
            align: 'center', 
            render: value => parseFloat(value).toFixed(4)+"%" 
        },
        {
            title: 'RAM',
            dataIndex: 'rentabilidadeMes',
            key: 'rentabilidadeMes',
            align: 'center', 
            render: value => parseFloat(value).toFixed(4)+"%"
        },
        {
            title: 'Operação',
            dataIndex: 'tipoOperacao',
            key: 'tipoOperacao',
            align: 'center',  
        },
    ]
    return (
        <Table column={columns} data={data} />
    )
}

export default StatementTable