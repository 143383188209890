import { Row, Col, Alert } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { ButtonComponent as Button } from "../../components"
import { getFileUrl } from "../../utils/functions"
import { ReactComponent as iconChevron } from '../../assets/img/chevron-left.svg'
import { ReactComponent as iconDownload } from '../../assets/img/download.svg'
import { downloadFile } from "../../utils/functions"

const ModalDocs = (props) => {
    const { user, type, handleType, closeModal, handleConfirm } = props
    const [url, setUrl] = useState()
    const [extension, setExtension] = useState()

    const newUrl = async () => {
        var path = await getFileUrl(type === 1 ? user.documento : user.comprovanteEndereco)
        const ext = path.substr(path.length - 3)
        setUrl(path)
        setExtension(ext)
    }

    useEffect(() => {
        newUrl()
    }, [])

    return (
        <Row>
            <Col md={5} className="info-wrapper">
                <h4 className="tx-gray font-bold font-16">Dados Gerais</h4>
                <div className="mt-15">
                    <label className="tx-pink font-bold">Nome</label><br />
                    <label className="tx-gray font-16">{user.nome}</label>
                </div>
                <div className="mt-15">
                    <label className="tx-pink font-bold">Data de Nascimento</label><br />
                    <label className="tx-gray font-16">{moment(user.nascimento).format("DD/MM/YYYY")}</label>
                </div>
                <div className="d-flex w-100 justify-between">
                    <div className="mt-15">
                        <label className="tx-pink font-bold">RG</label><br />
                        <label className="tx-gray font-16">{user.rg}</label>
                    </div>
                    <div className="mt-15">
                        <label className="tx-pink font-bold">CPF</label><br />
                        <label className="tx-gray font-16">{user.cpf}</label>
                    </div>
                </div>
                <div className="mt-15">
                        <label className="tx-pink font-bold">Filiação</label><br />
                        <label className="tx-gray font-16">{user.nomeMae}</label>
                    </div>
                <hr />
                <h4 className="tx-gray font-bold font-16 mt-15">Residência</h4>
                <div className="mt-15">
                    <label className="tx-pink font-bold">Endereço</label><br />
                    <label className="tx-gray font-16">{user.endereco}</label>
                </div>
                <div className="d-flex w-100 justify-between">
                    <div className="mt-15">
                        <label className="tx-pink font-bold">Número</label><br />
                        <label className="tx-gray font-16">{user.numero}</label>
                    </div>
                    <div className="mt-15">
                        <label className="tx-pink font-bold">Complemento</label><br />
                        <label className="tx-gray font-16">{user.complemento === 'undefined' ? '' : user.complemento}</label>
                    </div>
                </div>
                <div className="mt-15">
                    <label className="tx-pink font-bold">CEP</label><br />
                    <label className="tx-gray font-16">{user.cep}</label>
                </div>
                <div className="mt-15">
                    <label className="tx-pink font-bold">Cidade/Estado</label><br />
                    <label className="tx-gray font-16">{user.cidade}/{user.estado}</label>
                </div>
            </Col>
            <Col md={1}></Col>
            <Col md={18} className="doc-wrapper">
                <Row>
                    <Col md={4} className="side-doc">
                        <Button type="document" className={type === 1 && "btn-document-active"} disabled={user.documento === null && true} onClick={() => handleType(1)}>Identidade</Button>
                        {user.nivelVerificacao === '011' ? <Alert className="mt-15" type="success" showIcon message="Documento de identidade aprovado" /> :
                        user.documento === null ? <Alert type="warning" className="mt-15" showIcon message="Documento de identidade não enviado" /> :
                        <>
                        <Button type="danger" className="mt-15" disabled={user.documento === null && true} onClick={() => handleConfirm(1, false)}>Reprovar</Button>
                        <Button type="success" className="mt-15" disabled={user.documento === null && true} onClick={() => handleConfirm(1, true)}>Aprovar</Button></>}<br />
                        <hr /><br />
                        <Button type="document" className={type === 2 && "btn-document-active"} disabled={user.comprovanteEndereco === null && true} onClick={() => handleType(2)}>Residência</Button>
                        {user.nivelVerificacao === '101' ? <Alert type="success" className="mt-15" showIcon message="Comprovante de residência aprovado" /> :
                        user.comprovanteEndereco === null ? <Alert type="warning" className="mt-15" showIcon message="Compravante de residência não enviado" /> :
                        <>
                        <Button type="danger" className="mt-15" disabled={user.comprovanteEndereco === null && true} onClick={() => handleConfirm(2, false)}>Reprovar</Button>
                        <Button type="success" className="mt-15" disabled={user.comprovanteEndereco === null && true} onClick={() => handleConfirm(2, true)}>Aprovar</Button></> }
                    </Col>
                    <Col md={20} className="col-iframe">
                        <Button type="back" icon={iconChevron} onClick={closeModal}>Voltar</Button>
                        {url && extension === "pdf" ? <iframe title="Documento de cliente" src={url} width={100} height="100px" frameBorder={0} /> : <img alt="Documento de cliente" src={url} /> }
                        <Button type="primary" icon={iconDownload} onClick={() => downloadFile(type === 1 ? user.documento : user.comprovanteEndereco)}>Download</Button>

                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default ModalDocs