export default [
    {
        name: '237 - Banco Bradesco S.A'
    },
    {
        name: '341 - Banco Itaú Unibanco S.A'
    },
    {
        name: '033 - Banco Santander S.A'
    },
    {
        name: '001 - Banco do Brasil S.A'
    },
    {
        name: '104 - Caixa Econômica Federal'
    },
    {
        name: '655 - Banco Votorantim S.A'
    },
    {
        name: '260 - Nu Pagamentos S.A'
    },
    {
        name: '077 - Banco Inter S.A'
    },
    {
        name: '336 - Banco C6 S.A'
    },
    {
        name: '380 - Picpay Serviços S.A'
    },
    {
        name: '654 - Banco A.J. Renner S.A'
    },
    {
        name: '246 - Banco ABC Brasil S.A'
    },
    {
        name: '075 - Banco ABN Amro S.A'
    },
    {
        name: '121 - Banco Agibank S.A'
    },
    {
        name: '025 - Banco Alfa S.A'
    },
    {
        name: '641 - Banco Alvorada S.A'
    },
    {
        name: '065 - Banco Andbank S.A'
    },
    {
        name: '213 - Banco Arbi S.A'
    },
    {
        name: '024 - Banco Bandepe S.A'
    },
    {
        name: '740 - Banco Barclays S.A'
    },
    {
        name: '096 - Banco BM&FBovespa'
    },
    {
        name: '318 - Banco BMG S.A'
    },
    {
        name: '752 - Banco BNP Paribas Brasil S.A'
    },
    {
        name: '248 - Banco Boavista Interatlântico S.A'
    },
    {
        name: '107 - Banco Bocom BBM S.A'
    },
    {
        name: '063 - Banco Bradescard S.A'
    },
    {
        name: '036 - Banco Bradesco BBI S.A'
    },
    {
        name: '122 - Banco Bradesco BERJ S.A'
    },
    {
        name: '204 - Banco Bradesco Cartões S.A'
    },
    {
        name: '394 - Banco Bradesco Financiamento S.A'
    },
    {
        name: '125 - Banco Brasil Plural S.A'
    },
    {
        name: '081 - Banco Brasileiro de Negócios S.A'
    },
    {
        name: '218 - Banco BS2 S.A'
    },
    {
        name: '208 - Banco BTG Pactual S.A'
    },
    {
        name: '473 - Banco Caixa Geral S.A'
    },
    {
        name: '42 - Banco Capital S.A'
    },
    {
        name: '040 - Banco Cargill S.A'
    },
    {
        name: '266 - Banco Cédula S.A'
    },
    {
        name: '739 - Banco Cetelem S.A'
    },
    {
        name: '233 - Banco Cifra S.A'
    },
    {
        name: '745 - Banco Citibank S.A'
    },
    {
        name: '241 - Banco Clássico S.A'
    },
    {
        name: '095 - Banco Confidence de Câmbio S.A'
    },
    {
        name: '756 - Banco Cooperativo do Brasil S.A - Bancoob'
    },
    {
        name: '748 - Banco Cooperativo Sicredi S.A'
    },
    {
        name: '222 - Banco Credit Agricole Brasil S.A'
    },
    {
        name: '505 - Banco Credit Suisse (Brasil) S.A'
    },
    {
        name: '069 - Banco Crefisa S.A'
    },
    {
        name: '003 - Banco da Amazônia S.A'
    },
    {
        name: '083 - Banco da China Brasil S.A'
    },
    {
        name: '707 - Banco Daycoval S.A'
    },
    {
        name: '070 - Banco de Brasília S.A'
    },
    {
        name: '250 - Banco de Crédito e Varejo S.A - BCV'
    },
    {
        name: '300 - Banco de La Nacion Argentina'
    },
    {
        name: '495 - Banco de La Provincia de Buenos Aires'
    },
    {
        name: '494 - Banco de La Republica Oriental del Uruguay'
    },
    {
        name: '047 - Banco do Estado de Sergipe S.A'
    },
    {
        name: '021 - Banco do Estado do Espírito Santo - Baneste S.A'
    },
    {
        name: '037 - Banco do Estado do Pará S.A'
    },
    {
        name: '041 - Banco do Estado do Rio Grande do Sul S.A'
    },
    {
        name: '004 - Banco do Nordeste do Brasil S.A'
    },
    {
        name: '265 - Banco Fator S.A'
    },
    {
        name: '224 - Banco Fibra S.A'
    },
    {
        name: '626 - Banco Ficsa S.A'
    },
    {
        name: '094 - Banco Finaxis S.A'
    },
    {
        name: '612 - Banco Guanabara S.A'
    },
    {
        name: '012 - Banco Inbursa S.A'
    },
    {
        name: '604 - Banco Industrial do Brasil S.A'
    },
    {
        name: '653 - Banco Inusval S.A'
    },
    {
        name: '630 - Banco Intercap S.A'
    },
    {
        name: '719 - Banco Internacional do Funchal S.A - Banif'
    },
    {
        name: '249 - Banco Investcred Unibanco S.A'
    },
    {
        name: '184 - Banco Itaú BBA S.A'
    },
    {
        name: '029 - Banco Itaú Consignado S.A'
    },
    {
        name: '652 - Banco Itaú Unibanco Holding S.A'
    },
    {
        name: '479 - Banco ItauBank S.A'
    },
    {
        name: '376 - Banco J.P. Morgan S.A'
    },
    {
        name: '074 - Banco J. Safra S.A'
    },
    {
        name: '217 - Banco John Deere S.A'
    },
    {
        name: '076 - Banco KDB S.A'
    },
    {
        name: '757 - Banco Keb Hana do Brasil S.A'
    },
    {
        name: '600 - Banco Luso Brasileiro S.A'
    },
    {
        name: '243 - Banco Máxima S.A'
    },
    {
        name: '389 - Banco Mercantil do Brasil S.A'
    },
    {
        name: '370 - Banco Mizuho do Brasil S.A'
    },
    {
        name: '746 - Banco Modal S.A'
    },
    {
        name: '066 - Banco Morgan Stanley S.A'
    },
    {
        name: '456 - Banco MUFG S.A'
    },
    {
        name: '007 - Banco Nacional de Desenvolvimento Econômico e Social - BNDES'
    },
    {
        name: '735 - Banco Neon S.A'
    },
    {
        name: '169 - Banco Olé Bonsucesso Consignado S.A'
    },
    {
        name: '079 - Banco Original do Agronegócio S.A'
    },
    {
        name: '212 - Banco Original S.A'
    },
    {
        name: '712 - Banco Ourinvest S.A'
    },
    {
        name: '623 - Banco Pan S.A'
    },
    {
        name: '611 - Banco Paulista S.A'
    },
    {
        name: '643 - Banco Pine S.A'
    },
    {
        name: '747 - Banco Rabobank International Brasil S.A'
    },
    {
        name: '088 - Banco Randon S.A'
    },
    {
        name: '633 - Banco Rendimento S.A'
    },
    {
        name: '741 - Banco Ribeirão Preto S.A'
    },
    {
        name: '120 - Banco Rodobens S.A'
    },
    {
        name: '422 - Banco Safra S.A'
    },
    {
        name: '743 - Banco Semear S.A'
    },
    {
        name: '754 - Banco Sistema S.A'
    },
    {
        name: '366 - Banco Société Générale Brasil S.A'
    },
    {
        name: '637 - Banco Sofisa S.A'
    },
    {
        name: '464 - Banco Sumitomo Mitsui Brasileiro S.A'
    },
    {
        name: '082 - Banco Topázio S.A'
    },
    {
        name: '634 - Banco Triângulo S.A'
    },
    {
        name: '018 - Banco Tricury S.A'
    },
    {
        name: '610 - Banco VR S.A'
    },
    {
        name: '119 - Banco Western Union do Brasil S.A'
    },
    {
        name: '124 - Banco Woori Bank do Brasil S.A'
    },
    {
        name: '755 - Bank of America Merrill Lynch S.A'
    },
    {
        name: '017 - BNY Mellon Banco S.A'
    },
    {
        name: '320 - China Construction Bank'
    },
    {
        name: '163 - Commerzbank Brasil S.A'
    },
    {
        name: '487 - Deutsche Bank'
    },
    {
        name: '064 - Goldman Sachs do Brasil'
    },
    {
        name: '062 - Hipercard'
    },
    {
        name: '132 - ICBC do Brasil S.A'
    },
    {
        name: '492 - ING Bank N.V'
    },
    {
        name: '399 - Kirton Bank S.A'
    },
    {
        name: '613 - Omni Banco S.A'
    },
    {
        name: '254 - Paraná Banco S.A'
    },
    {
        name: '751 - Scotiabank Brasil S.A'
    },
]   