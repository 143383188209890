import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { request, getInfoLocalStorage, API_URL } from "../api/config"

const stateFromLocalStorage = getInfoLocalStorage() 

const initialState = {
    isLoading: false,
    success: false,
    message: null,
    withdrawls: [],
    isSubmit: false,
    successAction: false
}

export const getWithdrawls = createAsyncThunk(
    'withdrawls/',
    async (thunkAPI) => {
        try {
            const res = await request('resgates/getResgatesPendentes')
            if(res.code === 51) {
                return ({ withdrawls: res.resgates })
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao retornar os resgates')
        }
    }
)

export const denyWithdrawl = createAsyncThunk(
    'withdrawls/deny',
    async (values, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const res = await request('resgates/denyResgate', 'PUT', {
                motivo: values.reason,
                resgate: values.idWithdrawl,
                adminId: id
            })
            if(res.code === 51) {
                return res
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao reprovar o resgate')
        }
    }
)

export const approveWithdrawl = createAsyncThunk(
    'withdrawls/approve',
    async (values, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const res = await request('resgates/confirmResgate', 'PUT', {
                resgate: values.idWithdrawl,
                adminId: id,
                idContrato: values.idContract
            })
            if(res.code === 51) {
                return res
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao aprovar o resgate')
        }
    }
)

export const quoteWithdrawl = createAsyncThunk(
    'withdrawls/quote',
    async (values, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const res = await request('resgates/cotizarResgate', 'PUT', {
                resgate: values.idWithdrawl,
                idContrato: values.idContract,
                cotacao: values.quota,
                adminId: id
            })
            if(res.code === 51) {
                return res
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao cotizar o resgate')
        }
    }
)

export const transferWithdrawl = createAsyncThunk(
    'withdrals/transfer',
    async (values, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const formData = new FormData()
            formData.append('idContrato', values.idContract)
            formData.append('resgate', values.idWithdrawl)
            formData.append('file', values.file)
            formData.append('adminId', id)
            const req = await fetch(API_URL + 'resgates/finalizarResgate', {
                method: 'PUT',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${getInfoLocalStorage().token}`
                }
            })
            const response = await req.json()
            if(response.code === 51) {
                return response
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao finalizar o resgate')
            }
        } catch(e) {
            return thunkAPI.rejectWithValue('Houve um erro ao finalizar o resgate')
        }
    } 
)

const withdrawlsSlice = createSlice({
    name: 'withdrawls',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getWithdrawls.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getWithdrawls.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true
                state.withdrawls = payload.withdrawls
            })
            .addCase(getWithdrawls.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
            .addCase(denyWithdrawl.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(denyWithdrawl.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(denyWithdrawl.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.successAction = false
                state.message = payload
            })
            .addCase(approveWithdrawl.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(approveWithdrawl.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(approveWithdrawl.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.successAction = false
                state.message = payload
            })
            .addCase(quoteWithdrawl.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(quoteWithdrawl.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(quoteWithdrawl.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.successAction = false
                state.message = payload
            })
            .addCase(transferWithdrawl.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(transferWithdrawl.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(transferWithdrawl.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.successAction = false
                state.message = payload
            })
    }
})

const { reducer } = withdrawlsSlice

export default reducer