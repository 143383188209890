import React, { useState, useEffect } from 'react'
import '../../assets/theme/theme.less'
import { LayoutComponent as Layout } from '../../components'
import { Row, Col, List } from 'antd'
import CardContrato from './cardContrato'
import UserInfo from './userInfo'
import MonitorTable from './monitorTable'
import { InnerSideBar } from '../../components'
import { Link } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUser } from '../../redux/slices/usersSlice'

const UsersProfile = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { isLoading, success, user } = useSelector(state => state.users)

    const [activeContracts, setActiveContracts] = useState([])
    const [expiredContracts, setExpiredContracts] = useState([])

    useEffect(() => {
        const data = { idClient: id }
        dispatch(getUser(data))
    }, [dispatch, id])

    useEffect(() => {
        if(user.Contratos && user.Contratos.length > 0) {
            setActiveContracts(user.Contratos.filter(contract => contract.status == 1 || contract.status == 3 || contract.status == 6))
            setExpiredContracts(user.Contratos.filter(contract => contract.status === 4 || contract.status === 8 || contract.status === 9 || contract.status === 10 || contract.status === 11 || contract.status === 12))
        }
    }, [user])

    return (
        <Layout width>
            <Row>
                <Col md={18}>
                    <h1 className="title page-title">Perfil do Cliente</h1>
                    <Row className="mt-15">
                        <Col md={24}>
                            <UserInfo user={user} isLoading={isLoading} success={success} />
                            <Row className="mt-25" id="contratos-ativos">
                                <Col md={4}>
                                    <label className="tx-placeholder font-bold">Contratos Ativos</label>
                                </Col>
                                <Col md={20} className="d-flex flex-column justify-center">
                                    <hr className="profile-divisor" />
                                    <Row gutter={[15,15]} className="mt-15 pl-20">
                                        {isLoading ? 'Carregando...' : activeContracts.length > 0 ? activeContracts.map(contract => (
                                            <Col md={8}>
                                                <Link to={`/contrato/${contract.id}`}>
                                                    <CardContrato contract={contract} active />
                                                </Link>
                                            </Col>
                                        )) : 'Sem contratos ativos'}
                                        
                                        {/* <Col md={8}>
                                            <Link to={`/cliente/${id}/novo-contrato`}>
                                                <div className="add-contract-wrapper">
                                                    <Icon component={iconPlus} />
                                                    <h4 className="tx-pink font-bold">Adicionar Contrato</h4>
                                                </div>
                                            </Link>
                                        </Col> */}
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-25" id="contratos-vencidos">
                                <Col md={4}>
                                    <label className="tx-placeholder font-bold">Contratos Vencidos</label>
                                </Col>
                                <Col md={20} className="d-flex flex-column justify-center">
                                    <hr className="profile-divisor" />
                                    <Row className="mt-15 pl-20">
                                        {isLoading ? 'Carregando...' : expiredContracts.length > 0 ? 
                                            <List
                                                grid={{ gutter: 16, column: 3}}
                                                dataSource={expiredContracts}
                                                pagination
                                                renderItem={item => (
                                                    <List.Item>
                                                        <CardContrato contract={item} active={false} />
                                                    </List.Item>
                                                )}
                                            /> : 'Sem contratos vencidos'}
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="mt-25" id="monitor">
                                <Col md={4}>
                                    <label className="tx-placeholder font-bold">Monitor</label>
                                </Col>
                                <Col md={20} className="d-flex flex-column justify-center">
                                    <hr className="profile-divisor" />
                                </Col>
                                <Col md={24} className="mt-15 monitor-table">
                                    <MonitorTable data={user.Atividades} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <InnerSideBar menu />
                </Col>
            </Row>
            
        </Layout>
    )
}

export default UsersProfile