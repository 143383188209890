import { configureStore } from "@reduxjs/toolkit";
import loginReducer from "./slices/loginSlice"
import usersReducer from "./slices/usersSlice"
import contributionsReducer from "./slices/contributionsSlice"
import withdrawlsReducer from "./slices/withdrawlsSlice"
import monitorsReducer from "./slices/monitorsSlice"
import contractsReducer from "./slices/contractsSlice"
import operationsReducer from "./slices/operationsSlice"
import walletReducer from "./slices/walletSlice"

export const store = configureStore({
    reducer: {
        login: loginReducer,
        users: usersReducer,
        contributions: contributionsReducer,
        withdrawls: withdrawlsReducer,
        monitors: monitorsReducer,
        contracts: contractsReducer,
        operations: operationsReducer,
        wallets: walletReducer
    }
})