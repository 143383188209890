import { TableComponent as Table } from '../../components'
import { ReactComponent as iconError } from '../../assets/img/error-red.svg'
import { ReactComponent as iconSuccess } from '../../assets/img/success-green.svg'
import Icon from '@ant-design/icons';
import moment from 'moment';

const MonitorTable = (props) => {
    const { data } = props
    
    const columns = [
        {
            title: 'Data',
            dataIndex: 'createdAt',
            key: 'data',
            align: 'center',
            render: createdAt => moment(createdAt).format('DD/MM/YYYY')
        },
        {
            title: 'URL',
            dataIndex: 'urlRequest',
            key: 'url',
            align: 'center',  
        },
        {
            title: 'Descrição',
            dataIndex: 'descricao',
            key: 'descricao',
            align: 'center',
            render: description => <span className="monitor-description">{description}</span>
        },
        {
            title: 'Código',
            dataIndex: 'codigo',
            key: 'codigo',
            align: 'center',
        },
        {
            title: 'Sucesso',
            dataIndex: 'sucesso',
            key: 'sucesso',
            align: 'center',
            render: success => success ? <Icon className="full-monitor-icon" component={iconSuccess} /> : <Icon className="full-monitor-icon" component={iconError} />
        },
    ]
    return (
        <Table column={columns} data={data} />
    )
}

export default MonitorTable