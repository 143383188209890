import '../../assets/theme/theme.less'
import { Row, Col, Input, Image, Form, Alert } from 'antd'
import { ButtonComponent as Button } from '../../components'
import logo from '../../assets/img/logo.png'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons';
import { onLoginFinish } from '../../redux/slices/loginSlice'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { Navigate } from 'react-router-dom'

const Login = () => {
    const dispatch = useDispatch()
    const [form] = Form.useForm()
    const navigate = useNavigate()
    const { isLoading, message, success, isAuth, id } = useSelector(state => state.login)

    const onFinish = async (values) => {
        dispatch(onLoginFinish(values))
    }

    useEffect(() => {
        if(isAuth) {
            navigate('/clientes/geral', { state: { id: id }})
        }
    }, [isAuth, id, navigate])

    const onFinishFailed = ({ errorFields }) => {
        form.scrollToField(errorFields[0].name)
    }

    return (
        isAuth ? <Navigate to="/clientes/geral" state={{ id }} /> :
        <>
            <Row>
                <Col md={24} flex align="middle" justify="center">
                    <div className="login-wrapper">
                        <Image src={logo} preview={false} />
                        {!success && message !== null && <Alert type="error" message={message} className="mt-50" showIcon /> }
                        <Form
                            className={!success && message !== null ? 'mt-15' : 'mt-50'}
                            layout="vertical"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            form={form}
                        >
                            <Form.Item
                                label="E-mail"
                                name="email"
                                rules={[{ required: true, message: 'E-mail é um campo obrigatório' }]}
                            >
                                <Input placeholder="E-mail" type="email" />
                            </Form.Item>
                            <Form.Item
                                label="Senha"
                                name="password"
                                rules={[{ required: true, message: 'Senha é um campo obrigatório' }]}
                            >
                                <Input.Password placeholder="Senha" type="password" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" active>{isLoading ? <LoadingOutlined /> : 'Entrar'}</Button>
                            </Form.Item>
                        </Form>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Login