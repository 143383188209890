import './inner.less'
import { ButtonComponent as Button } from '../../components'
import { ReactComponent as iconChevron } from '../../assets/img/chevron-left.svg'
import { useNavigate } from 'react-router-dom'
import { Layout } from 'antd'
import { HashLink as Link } from 'react-router-hash-link'
import { useState } from 'react'

const { Sider } = Layout

const InnerSideBar = (props) => {
    const { menu, buttons, handleModal } = props
    const navigate = useNavigate()
    const [activeSession, setActiveSession] = useState(1)
    
    const onClick = (i) => {
        setActiveSession(i)
    }

    return (
        <Sider
            className="inner-wrapper"
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                right: '3vw',
                bottom: 0,
                width: '100%',
            }}
            width={"200px"}
        >
            <Button onClick={() => navigate(-1)} type="back" className="w-50" icon={iconChevron}>Voltar</Button>
            {menu &&
                <div className="inner-menu-wrapper">
                    <div className={`menu-item ${activeSession === 1 && 'item-active'}`}>
                        <Link smooth to="#dados" onClick={() => onClick(1)}><label>Dados Gerais</label></Link>
                    </div>
                    <div className={`menu-item ${activeSession === 2 && 'item-active'}`}>
                        <Link smooth to="#residencia" onClick={() => onClick(2)}><label>Residência</label></Link>
                    </div>
                    <div className={`menu-item ${activeSession === 3 && 'item-active'}`}>
                        <Link smooth to="#contratos-ativos" onClick={() => onClick(3)}><label>Contratos Ativos</label></Link>
                    </div>
                    <div className={`menu-item ${activeSession === 4 && 'item-active'}`}>
                        <Link smooth to="#contratos-vencidos" onClick={() => onClick(4)}><label>Contratos Vencidos</label></Link>
                    </div>
                    <div className={`menu-item ${activeSession === 5 && 'item-active'}`}>
                        <Link smooth to="#monitor" onClick={() => onClick(5)}><label>Monitor</label></Link>
                    </div>
                </div> }
            {buttons &&
                <>
                    <div className="mt-10">
                        <Button type="action" onClick={() => handleModal('withdrawl')}>Novo Resgate</Button>
                        <Button type="action" className="mt-10" onClick={() => handleModal('contribution')}>Novo Aporte</Button>
                    </div>
                    {/* <Button type="danger" className="btn-finish-contract">Finalizar contrato</Button> */}
                </>
            }
        </Sider>
    )

}

export default InnerSideBar