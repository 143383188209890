export const buttonsClient = [
    {
        title: "Geral",
        notification: false,
        link: "/clientes/geral",
        key: "geral"
    }, {
        title: "Pendentes",
        notification: 100,
        link: "/clientes/pendentes",
        key: "pendentes"
    }
]
export const buttonsApprovals = [
    {
        title: "Aportes",
        active: false,
        notification: 100,
        link: "/aprovacoes/aportes",
        key: "aportes"
    }, {
        title: "Resgates",
        notification: 100,
        link: "/aprovacoes/resgates",
        key: "resgates"
    }, {
        title: "ASA",
        notification: 100,
        active: false,
        link: "/aprovacoes/asa",
        key: "asa"
    }, {
        title: "SPIRIT",
        notification: 100,
        link: "/aprovacoes/spirit",
        key: "spirit"
    }
]

export const buttonsWallet = [
    {
        title: "Conversões",
        notification: false,
        link: "/wallet/conversoes",
        key: "conversoes"
    }, {
        title: "Tokens",
        notification: 100,
        link: "/wallet/tokens-aprovacao",
        key: "tokens"
    }
]