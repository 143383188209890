import React, { useEffect, useState } from 'react'
import '../../assets/theme/theme.less'
import './new-contract.less'
import { LayoutComponent as Layout } from '../../components'
import { Row, Col, Input, Select, Upload } from 'antd'
import { InnerSideBar, UserWrapper, ButtonComponent as Button, ButtonTabsComponent as ButtonTab } from '../../components'
import { ReactComponent as iconUpload } from '../../assets/img/upload.svg'
import Icon from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getUser } from '../../redux/slices/usersSlice'
import banks from './banks'

const { Option } = Select
const { Dragger } = Upload

const options = [
    {
        value: 15,
        text: '4 meses'
    }, {
        value: 16,
        text: '8 meses'
    }, {
        value: 17,
        text: '10 meses'
    }, {
        value: 18,
        text: '12 meses'
    }
]

const NewContract = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { isLoading, user } = useSelector(state => state.users)
    const [contract, setContract] = useState('spirit')

    const handleSwitch = (type) => {
        setContract(type)
    }

    useEffect(() => {
        const data = { idClient: id }
        dispatch(getUser(data))
    }, [dispatch, id])

    return (
        <Layout width>
            <Row>
                <Col md={18}>
                    <h1 className="title page-title">Novo Contrato</h1>
                    <Row className="mt-30">
                        <Col md={4}></Col>
                        <Col md={16}>
                            <div className="switch">
                                <div onClick={() => handleSwitch('spirit')}><ButtonTab text="SPIRIT" active={contract === 'spirit' ? true : false} /></div>
                                <div onClick={() => handleSwitch('asa')}><ButtonTab text="ASA" active={contract === 'asa' ? true : false} /></div>
                            </div> 
                        </Col>
                        <Col md={4}></Col>
                        <Col md={4}></Col>
                        <Col md={16}>
                            {isLoading ? 'Carregando' : <UserWrapper user={user} />}
                        </Col>
                        <Col md={4}></Col>
                    </Row>
                    <Row className="mt-25">
                        <Col md={4}>
                            <label className="tx-placeholder font-bold">Dados do Contrato</label>
                        </Col>
                        <Col md={20}>
                            <hr className="profile-divisor" />
                        </Col>
                        <Col md={4}></Col>
                        <Col md={16} className="mt-30">
                            <div className="form-wrapper">
                                <div className="d-flex w-100">
                                    <div className="w-50">
                                        <label className="tx-pink font-bold">Valor do Investimento</label>
                                        <Input className="mt-10" />
                                    </div>
                                    <div className="w-50 pl-5 p-15">
                                        {
                                            contract === 'asa' && 
                                            <>
                                                <label className="tx-pink font-bold">Opção de vencimento</label>
                                                <Select className="mt-10">
                                                    {options.map(i => (
                                                        <Option value={i.value}>{i.text}</Option>
                                                    ))}
                                                </Select>
                                            </>
                                        }
                                    </div>
                                </div>
                                <div className="d-flex w-100 mt-25">
                                    <div className="w-50">
                                        <label className="tx-pink font-bold">Banco</label>
                                        <Select className="mt-10" showSearch>
                                            {banks.map(i => (
                                                <Option value={i.name}>{i.name}</Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div className="w-25 pl-5 p-15">
                                        <label className="tx-pink font-bold">Agência</label>
                                        <Input className="mt-10" />
                                    </div>
                                    <div className="w-25 pl-5 p-15">
                                        <label className="tx-pink font-bold">Conta</label>
                                        <Input className="mt-10" />
                                    </div>
                                </div> 
                                <div className="w-100 mt-25">
                                    <label className="tx-pink font-bold">Comprovante de Depósito</label>
                                    <Dragger height={100} className="mt-10">
                                        <div className="d-flex justify-center align-center">
                                            <Icon component={iconUpload} className="font-22" />
                                            <label className="tx-gray font-bold ml-5">Clique ou arraste o arquivo aqui</label>
                                        </div>
                                    </Dragger>
                                </div>
                                <div className="d-flex w-100">
                                    <div className="w-50"></div>
                                    <div className="w-25 mt-25 d-flex align-center justify-center">
                                        <label className="tx-red pointer">Cancelar</label>
                                    </div>
                                    <div className="w-25 mt-25">
                                        <Button type="success">Criar Contrato</Button>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md={4}></Col>
                    </Row>
                </Col>
                <Col md={6}>
                    <InnerSideBar menu={false} />
                </Col>
            </Row>
            
        </Layout>
    )
}

export default NewContract