import { Row, Col, Input } from 'antd'
import moment from 'moment'
import { downloadFile } from '../../utils/functions'
import { ReactComponent as iconDownload } from '../../assets/img/download.svg'
import { ButtonComponent as Button } from '../../components'
import { useState } from 'react'

const DenyContribution = (props) => {
    const { contribution, handleModal, deny } = props
    const [reason, setReason] = useState('')

    const handleChange = (e) => {
        setReason(e.target.value)
    }

    const denyContribution = () => {
        const { contribution } = props
        handleModal()
        deny(reason, contribution.id, false)
    }

    return (
        <>
        <Row>
            <Col md={20} className="d-flex align-center">
                <h1 className="font-bold font-28 tx-white">Reprovação de Aporte</h1>                
            </Col>
            <Col md={4} className="d-flex align-center justify-end">
                <label className="tx-white btn-close" onClick={handleModal}>Cancelar</label>
            </Col>
        </Row>
        <Row className="deny-info-wrapper">
            <Col md={12} className="border">
                <Row>
                    <Col md={20}>
                        <h3 className="tx-pink font-bold">{contribution.Contrato.Cliente.nome}</h3>
                    </Col>
                    <Col md={4}>
                        <label className="tx-pink font-bold">{contribution.Contrato.tipoContrato == 6 ? "SPIRIT" : "ASA"}</label><br />
                        <label className="tx-gray font-16">#{contribution.Contrato.id}</label>
                    </Col>
                    <Col md={24}>
                        <hr />
                    </Col>
                    <Col md={12} className="mt-10">
                        <label className="tx-pink font-bold">Solicitação</label><br />
                        <label className="tx-gray">{moment(contribution.createAt).format("DD/MM/YYYY")}</label>
                    </Col>
                    <Col md={24} className="mt-10">
                        <hr />
                    </Col>
                    <Col md={24} className="mt-10">
                        <label className="tx-pink font-bold">Valor</label><br />
                        <label className="tx-gray font-bold font-22">R$ {contribution.valor}</label>
                    </Col>
                    <Col md={16} className="mt-10">
                        <Button type="primary" icon={iconDownload} active onClick={() => downloadFile(contribution.comprovante)}>Download comprovante</Button>
                    </Col>
                </Row>
            </Col>
            <Col md={12} className="pl-20">
                <label className="tx-pink font-bold font-16">Motivo da reprovação</label>
                <Input.TextArea placeholder="Digite aqui o motivo da reprovação" className="mt-15 bg-white" onChange={handleChange} value={reason} />
                <Row className="mt-30">
                    <Col md={12}>
                        <label className="tx-gray btn-close" onClick={handleModal}>Cancelar</label>
                    </Col>
                    <Col md={12}>
                        <Button type="danger" onClick={denyContribution}>Reprovar</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
    )
}

export default DenyContribution