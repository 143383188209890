import React, { useState } from 'react'
import '../../assets/theme/theme.less'
import { LayoutComponent as Layout, TabsMenuComponent as TabsMenu } from '../../components'
import { Row, Col, List, message as alert } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getTokens, handleToken } from '../../redux/slices/walletSlice'
import CardTokens from './cardTokens'

const WalletTokens = () => {

    const dispatch = useDispatch()
    const { isLoading, success, tokens, isSubmit, successAction } = useSelector(state => state.wallets)
    const [clicked, setClicked] = useState(0)
    const [approved, setApproved] = useState(false)

    const onClick = (id, isApproved, idClient, status) => {
        setClicked(id)
        setApproved(isApproved)
        const data = { id, confirmed: status, idClient }
        dispatch(handleToken(data)).then((res) => {
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                setTimeout(() => {
                    dispatch(getTokens())
                }, 1000)
            }
        })
    }

    useEffect(() => {
        dispatch(getTokens())
    }, [dispatch])

    return (
        <Layout width>
            <Row>
                <Col md={24}>
                    <h1 className="title page-title">Tokens</h1>
                </Col>
                <Col md={24} className="mt-15 d-flex align-center">
                    <div className="d-flex align-center m-auto">
                        <TabsMenu type="wallet" />
                    </div>
                </Col>
                <Col md={24} className="mt-50">
                    {isLoading ? 'Carregando' : success ?
                        <List
                            grid={{ gutter: 16, column: 3}}
                            dataSource={tokens}
                            pagination
                            renderItem={item => (
                                <List.Item>
                                    <CardTokens token={item} clicked={clicked} approved={approved} isSubmit={isSubmit} success={successAction} onClick={onClick} />
                                </List.Item>
                            )}
                        /> : 'Houve um erro ao carregar os tokens'
                    }
                </Col>
            </Row>
            
        </Layout>
    )
}

export default WalletTokens