import React, { useState } from 'react'
import { Card, Row, Col, Modal, Upload } from 'antd'
import { ButtonComponent as Button } from '../../components'
import moment from 'moment'
import { ReactComponent as iconUpload } from '../../assets/img/upload.svg'
import { LoadingOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import DenyWithdrawl from './denyWithdrawl'
import AddQuota from './addQuota'
import { ReactComponent as iconError } from '../../assets/img/error.svg'
import { ReactComponent as iconCheck } from '../../assets/img/check.svg'
import { maskCpf, maskCurrency } from '../../utils/functions';

const { Dragger } = Upload

const CardWithdrawls = (props) => {
    const [openModal, setOpenModal] = useState(false)
    const [openModalQuota, setOpenModalQuota] = useState(false)
    const [showUpload, setShowUpload] = useState(false)
    const [showId, setShowId] = useState(0)
    const [file, setFile] = useState(null)
    const { status, withdrawl, isSubmit, success, deny, clicked, approved, approve, quote, onTransfer } = props
    const handleModal = () => {
        const aux = openModal
        setOpenModal(!aux)
    }

    const handleModalQuota = () => {
        const aux = openModalQuota
        setOpenModalQuota(!aux)
    }

    const handleShowUpload = (idWithdrawl) => {
        setShowId(idWithdrawl)
        setShowUpload(true)
    }

    const beforeUpload = file => {
        setFile(file)
        return false
    }

    const classes = {
        pending: 'card-pending',
        transfer: 'card-transfer',
        quota: 'card-quota'
    }

    const returnStatus = {
        pending: 'aprovado',
        quota: 'cotizado',
        transfer: 'transferido'
    }

    return (
        <>
        <Card className={`${classes[status]} ${success && clicked === withdrawl.id && !isSubmit ? approved ? 'card-success' : 'card-reproved' : ''}`} style={{height: showId === withdrawl.id && 'auto'}}>
            {success && clicked === withdrawl.id && !isSubmit ? approved ?
                <div className="d-flex align-center justify-center flex-column">
                    <Icon component={iconCheck} />
                    <h1 className="tx-white font-bold mt-15 font-22 text-center">Resgate {returnStatus[status]}</h1>
                </div> :
                <div className="d-flex align-center justify-center flex-column">
                    <Icon component={iconError} />
                    <h1 className="tx-white font-bold mt-15 font-22 text-center">Resgate reprovado</h1>
                </div> :
            <>
            <Row>
                <Col md={12}>
                    <h4 className="tx-pink card-title font-16">{withdrawl.Contrato.Cliente.nome}</h4>
                </Col>
                <Col md={4}></Col>
                <Col md={8}>
                    <h4 className="tx-pink card-title">{withdrawl.Contrato.tipoContrato === 6 ? 'SPIRIT' : 'ASA'}</h4>
                    <label className="tx-gray">#{withdrawl.Contrato.id}</label>
                </Col>
            </Row>
            <hr />
            {status === 'pending' && 
                <Row>
                    <Col md={12}>
                        <label className="tx-pink font-bold">Solicitação</label><br />
                        <label className="tx-gray">{moment(withdrawl.dataSolicitacao).format('DD/MM/YYYY')}</label>
                    </Col>
                </Row>
            }
            {status === 'quota' &&
                <Row>
                    <Col md={12}>
                        <label className="tx-pink font-bold">Aprovação</label><br />
                        <label className="tx-gray">{moment(withdrawl.dataAprovacao).format('DD/MM/YYYY')}</label>
                    </Col>
                    <Col md={12}>
                        <label className="tx-pink font-bold">Limite cotização</label><br />
                        <label className="tx-gray">{moment(withdrawl.dataCotizacao).format('DD/MM/YYYY')}</label>
                    </Col>
                </Row>
            }
            {status === 'transfer' &&
                <Row>
                    <Col md={12}>
                        <label className="tx-pink font-bold">Cotização</label><br />
                        <label className="tx-gray">{moment(withdrawl.dataCotizacao).format('DD/MM/YYYY')}</label>
                    </Col>
                    <Col md={12}>
                        <label className="tx-pink font-bold">Limite transferência</label><br />
                        <label className="tx-gray">{moment(withdrawl.dataTransferencia).format('DD/MM/YYYY')}</label>
                    </Col>
                </Row>
            }
            <hr />
            <Row>
                <Col md={24}>
                    <label className="tx-pink font-bold">Valor</label><br />
                    <label className="tx-gray font-22 font-bold">R${maskCurrency(withdrawl.valor)}</label>
                </Col>
            </Row>
            {status === 'transfer' &&
                <>
                    <hr />
                    <Row>
                        <Col md={24}>
                            <label className="tx-pink font-bold font-16">Dados Bancários</label><br />
                            <label className="tx-gray mt-10">{withdrawl.Contrato.Cliente.nome}</label>
                        </Col>
                        <Col md={24} className="mt-15">
                            <label className="tx-pink font-bold">CPF</label><br />
                            <label className="tx-gray">{maskCpf(withdrawl.Contrato.Cliente.cpf)}</label>
                        </Col>
                        <Col md={24} className="mt-15">
                            <label className="tx-pink font-bold">Banco</label><br />
                            <label className="tx-gray">{withdrawl.Contrato.codigoBanco} - {withdrawl.Contrato.nomeBanco}</label>
                        </Col>
                        <Col md={12} className="mt-15">
                            <label className="tx-pink font-bold">Agência</label><br />
                            <label className="tx-gray">{withdrawl.Contrato.numeroAgencia}</label>
                        </Col>
                        <Col md={12} className="mt-15">
                            <label className="tx-pink font-bold">Conta</label><br />
                            <label className="tx-gray">{withdrawl.Contrato.numeroConta}</label>
                        </Col>
                        {showUpload && showId === withdrawl.id ? '' :
                        <Col md={24} className="mt-15">
                            <Button icon={iconUpload} type="primary" onClick={() => handleShowUpload(withdrawl.id)}>Upload Comprovante</Button>
                        </Col>}
                        {showUpload && showId === withdrawl.id ?
                        <Col md={24}>
                            <Dragger height={100} className="mt-10" beforeUpload={beforeUpload} onRemove={() => setFile(null)}>
                                <div className="d-flex justify-center align-center">
                                    <Icon component={iconUpload} className="font-22" />
                                    <label className="tx-gray font-bold ml-5">Clique ou arraste o arquivo aqui</label>
                                </div>
                            </Dragger>
                            <Button type="primary" onClick={() => onTransfer(withdrawl.Contrato.id, withdrawl.id, file)}>{isSubmit && approved && clicked === withdrawl.id ? <LoadingOutlined /> : "Enviar Comprovante"}</Button>
                        </Col> : ''}
                    </Row>
                </>
            }
            {status === 'pending' &&
                <Row className="mt-15">
                    <Col md={12} className="pr-5 mt-15">
                        <Button type="danger" onClick={handleModal} disabled={isSubmit && clicked === withdrawl.id ? true : false}>{isSubmit && !approved && clicked === withdrawl.id ? <LoadingOutlined /> : "Reprovar"}</Button>
                    </Col>
                    <Col md={12} className="pl-5 mt-15">
                        <Button type="success" disabled={isSubmit && clicked === withdrawl.id ? true : false} onClick={() => approve(withdrawl.id, withdrawl.Contrato.id, true)}>{isSubmit && approved && clicked === withdrawl.id ? <LoadingOutlined /> : "Aprovar"}</Button>
                    </Col>
                </Row> } 
            {status === 'quota' &&
                <Row className="mt-15">
                    <Col md={24}>
                        <Button type="primary" disabled={isSubmit && clicked === withdrawl.id ? true : false} active onClick={handleModalQuota}>{isSubmit && approved && clicked === withdrawl.id ? <LoadingOutlined /> : "Inserir Cotação"}</Button>
                    </Col>
                </Row>
            }
            </>}
        </Card>
        <Modal
            visible={openModal}
            centered
            footer={null}
            closable={false}
            maskClosable={false}
            className="modal-deny"
            width="70vw"
        >
            <DenyWithdrawl withdrawl={withdrawl} handleModal={handleModal} deny={deny} />
        </Modal>
        <Modal
            visible={openModalQuota}
            centered
            footer={null}
            closable={false}
            maskClosable={false}
            className="modal-deny"
            width="70vw"
        >
            <AddQuota withdrawl={withdrawl} handleModal={handleModalQuota} quote={quote} />
        </Modal>
        </>
    )
}

export default CardWithdrawls