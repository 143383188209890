import React from 'react'
import { Card, Row, Col, Modal } from 'antd'
import { ButtonComponent as Button } from '../../components'
import { ReactComponent as iconDownload } from '../../assets/img/download.svg'
import { ReactComponent as iconError } from '../../assets/img/error.svg'
import { ReactComponent as iconCheck } from '../../assets/img/check.svg'
import moment from 'moment'
import { downloadFile } from '../../utils/functions'
import { LoadingOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import ApproveContract from '../../components/ApproveContract'
import { maskCurrency } from '../../utils/functions'

const CardContracts = (props) => {
    const { contract, clicked, isSubmit, success, repprove, contractApproved, openModal, handleModal, approve, error } = props

    return (
        <>
        <Card className={`${success && !isSubmit && clicked === contract.id ? contractApproved ? 'card-success' : 'card-reproved' : '' }`}>
            {success && clicked === contract.id && !isSubmit ? contractApproved ?
                <div className="d-flex align-center justify-center flex-column">
                    <Icon component={iconCheck} />
                    <h1 className="tx-white font-bold mt-15 font-22 text-center">Contrato aprovado</h1>
                </div> :
                <div className="d-flex align-center justify-center flex-column">
                    <Icon component={iconError} />
                    <h1 className="tx-white font-bold mt-15 font-22 text-center">Contrato reprovado</h1>
                </div> :
            <>
            <Row>
                <Col md={12}>
                    <h4 className="tx-pink card-title font-16">{contract.Cliente.nome}</h4>
                </Col>
                <Col md={4}></Col>
                <Col md={8}>
                    <h4 className="tx-pink card-title">ID</h4>
                    <label className="tx-gray">#{contract.id}</label>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col md={12}>
                    <label className="tx-pink font-bold">Tipo Contrato</label><br />
                    <label className="tx-gray">{contract.duracao} Meses</label>
                </Col>
                <Col md={12}>
                    <label className="tx-pink font-bold">Data de solicitação</label><br />
                    <label className="tx-gray">{moment(contract.createdAt).format("DD/MM/YYYY")}</label>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col md={24}>
                    <label className="tx-pink font-bold">Valor</label><br />
                    <label className="tx-gray font-22 font-bold">R${maskCurrency(contract.valorInicial)}</label>
                </Col>
            </Row>
            <hr />
            <Row className="mt-15">
                <Col md={24}>
                    <Button type="primary" icon={iconDownload} active onClick={() => downloadFile(contract.comprovante)}>Download comprovante</Button>
                </Col>
                <Col md={12} className="pr-5 mt-15">
                    <Button type="danger" disabled={isSubmit && clicked === contract.id ? true : false} onClick={() => repprove(contract.id)}>{isSubmit && !contractApproved && clicked === contract.id ? <LoadingOutlined /> : "Reprovar"}</Button>
                </Col>
                <Col md={12} className="pl-5 mt-15">
                    <Button type="success" disabled={isSubmit && clicked === contract.id ? true : false} onClick={handleModal}>{isSubmit && contractApproved && clicked === contract.id ? <LoadingOutlined /> : "Aprovar"}</Button>
                </Col>
            </Row>
            </>}
        </Card>
        <Modal
            centered
            visible={openModal}
            footer={null}
            closable={false}
            maskClosable={false}
            className="modal-deny"
            width="70vw"
        >
            <ApproveContract error={error} contract={contract} handleModal={handleModal} approve={approve} />
        </Modal>
        </>
    )
}

export default CardContracts