import { Row, Col, Card } from 'antd'
import { maskCurrency } from '../../utils/functions'
import moment from 'moment'

const asa = [
    {
        title: 'Total Flow.in USD',
        key: 'saldoQuery',
        index: 'totalSaidaUsd',
        currency: 'US$'
    },
    {
        title: 'Total Clientes Flow.in',
        key: 'saldoQuery',
        index: 'totalClientesFlow',
        currency: 'R$'
    },
    {
        title: `Lucro Flow.in ${moment().subtract(1, 'month').format("MM/YYYY")}`,
        key: 'lucro',
        index: 'totalLucroFlow',
        currency: 'R$'
    }
]

const spirit = [
    {
        title: 'Total Flow.in USD',
        key: 'saldoQuery',
        index: 'totalSaidaUsd',
        currency: 'US$'
    }, 
    {
        title: 'Total AUM Spirit',
        key: 'saldoQuery',
        index: 'totalClientesFlow',
        currency: 'R$'
    },
    {
        title: 'Diferencial AUM e Σ clientes',
        key: 'saldoQuery',
        index: 'desvio',
        currency: 'R$'
    },
    {
        title: `Lucro Flow.in ${moment().subtract(1, 'month').format("MM/YYYY")}`,
        key: 'lucroSpirit',
        index: 'totalLucroFlow',
        currency: 'R$'
    },
    {
        title: `Total AUM início ${moment().subtract(1, 'month').format("MM/YYYY")}`,
        key: 'aumBeginning',
        index: 'totalAUM',
        currency: 'R$'
    },
    {
        title: `Total AUM final ${moment().subtract(1, 'month').format("MM/YYYY")}`,
        key: 'aumEnd',
        index: 'totalAUM',
        currency: 'R$'
    }
]


const OperationsBalance = (props) => {
    const { type, balance, isLoading } = props
    return (
        <Row gutter={[15, 15]}>
            {type === 'asa' ?
                asa.map(i => (
                    <Col md={8}>
                        <Card title={i.title} headStyle={{color: '#DB56E0', fontWeight: 'bold'}}>
                            {isLoading ? 'Carregando valor' : <h4 className="font-bold tx-gray font-28">{i.currency}{maskCurrency(balance[i.key][i.index])}</h4>}
                        </Card>
                    </Col>
                )) :
                spirit.map(i => (
                    <Col md={8}>
                        <Card title={i.title} headStyle={{color: '#DB56E0', fontWeight: 'bold'}}>
                            {isLoading ? 'Carregando valor' : <h4 className="font-bold tx-gray font-28">{i.currency}{maskCurrency(i.index === 'desvio' ? (!!balance['saldoQuery'] ? balance['saldoQuery']['totalClientesFlow']-balance['saldoQuery']['desvio']:'') : (!!balance[i.key] ? balance[i.key][i.index] : ""))}</h4>}
                        </Card>
                    </Col>
                ))
            }
        </Row>
    )
}

export default OperationsBalance