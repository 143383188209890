import { TableComponent as Table } from '../../components'
import { ReactComponent as iconUpload } from '../../assets/img/upload.svg'
import Icon from '@ant-design/icons';
import moment from 'moment';
import { downloadFile, maskCurrency } from '../../utils/functions'

const ContractTable = (props) => {
    const { data, type } = props

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Valor',
            dataIndex: 'valor',
            key: 'valor',
            align: 'center',  
            render: valor => 'R$ '+maskCurrency(valor)
        },
        {
            title: 'Data',
            dataIndex: type === 'contribution' ? 'dataAprovacao' : 'dataTransferencia',
            key: 'date',
            align: 'center',
            render: date => moment(date).format('DD/MM/YYYY')
        },
        {
            title: 'Comprovante',
            dataIndex: 'comprovante',
            key: 'file',
            align: 'center',
            render: file => <Icon className="contract-icon" component={iconUpload} onClick={() => downloadFile(file)} />
        },
    ]
    return (
        <Table column={columns} data={data} />
    )
}

export default ContractTable