import React, { useEffect, useState} from 'react'
import '../../assets/theme/theme.less'
import { LayoutComponent as Layout, TabsMenuComponent as TabsMenu } from '../../components'
import { Row, Col, List, message as alert } from 'antd'
import CardContracts from './cardContract'
import { getContracts, repproveContract, approveContract } from '../../redux/slices/contractsSlice'
import { useDispatch, useSelector } from 'react-redux'

const ApprovalsContracts = () => {
    const dispatch = useDispatch()
    const [clicked, setClicked] = useState(0)
    const [openModal, setOpenModal] = useState(false)
    const [contractApproved, setContractApproved] = useState(false)
    const [error, setError] = useState(false)
    const { isLoading, success, contracts, isSubmit, successAction } = useSelector(state => state.contracts)

    const repprove = (idContract) => {
        setClicked(idContract)
        setContractApproved(false)
        const data = { idContract }
        dispatch(repproveContract(data)).then((res) => {
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                setTimeout(() => {
                    const data = { status: 2 }
                    dispatch(getContracts(data))
                }, 1000)
            }
        })
    }

    const approve = (idContract, quota, initialValue) => {
        const data = { idContract, quota, initialValue }
        if(quota === 0 || quota < 0 || quota === '') {
            setError(true)
        } else {
            setClicked(idContract)
            setContractApproved(true)
            handleModal()
            dispatch(approveContract(data)).then((res) => {
                if(res?.error?.message) {
                    alert.error(res.payload)
                } else {
                    setTimeout(() => {
                        const data = { status: 2 }
                        dispatch(getContracts(data))
                    }, 1000)
                }
            })
        }
    }

    const handleModal = () => {
        var aux = openModal
        setOpenModal(!aux)
    }
    
    useEffect(() => {
        const data = { status: 2 }
        dispatch(getContracts(data))
    }, [dispatch])
    
    return (
        <Layout width>
            <Row>
                <Col md={24}>
                    <h1 className="title page-title">Aprovações</h1>
                </Col>
                <Col md={24} className="mt-15 d-flex align-center">
                    <div className="d-flex align-center m-auto">
                        <TabsMenu type="approvals" />
                    </div>
                </Col>
            </Row>
            <Row className="mt-25">
                {isLoading ? 'Carregando' : success ?
                        <List
                            grid={{ gutter: 16, column: 3}}
                            dataSource={contracts}
                            pagination
                            renderItem={item => (
                                <List.Item>
                                    <CardContracts error={error} contract={item} clicked={clicked} isSubmit={isSubmit} success={successAction} repprove={repprove} contractApproved={contractApproved} openModal={openModal} handleModal={handleModal} approve={approve} />
                                </List.Item>
                            )}
                        /> : 'Houve um erro ao carregar os contratos'
                }
            </Row>
        </Layout>
    )
}

export default ApprovalsContracts