import React, { useState } from 'react'
import { Card, Select, Input } from 'antd'
import { ButtonComponent as Button } from '../../components'
import moment from 'moment'
import { useEffect } from 'react'
import { maskCurrency } from '../../utils/functions'
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select

const CardContract = (props) => {
    const { type, contract, handleContract, clicked, error, isSubmit } = props
    const [showOptions, setShowOptions] = useState(false)
    const [showInput, setShowInput] = useState(false)
    const [withdrawlValue, setWithdrawlValue] = useState(0)
    const [contributionValue, setContributionValue] = useState(0)
    const [renewalType, setRenewalType] = useState(0)
    const [contractType, setContractType] = useState(0)
    const [amount, setAmount] = useState(0)

    const handleOptions = () => {
        setShowOptions(true)
    }

    const handleRenovationChange = (e) => {
        if(e === 10 || e === 11) {
            setShowInput(true)
        } else {
            setShowInput(false)
        }
        setRenewalType(e)
    }

    const handleContractType = (e) => {
        setContractType(e)
    }

    const handleAmount = (e) => {
        setAmount(e.target.value)
    }

    useEffect(() => {
        var withdrawl = contract.Resgates.filter(resgates => parseInt(resgates.status) === 5)
        if(withdrawl[0] !== undefined) {
            setWithdrawlValue(withdrawl[0].valor)
        }
        var contribution = contract.Aportes.filter(aportes => parseInt(aportes.status) === 4)
        if(contribution[0] !== undefined) {
            setContributionValue(contribution[0].valor)
        }
    }, [])

    return (
        <Card>
            <div className="d-flex justify-between">
                <label className="tx-pink font-bold font-16 w-50">{contract.Cliente.nome}</label>
                <div>
                    <label className="tx-pink font-bold">ID</label><br />
                    <label className="tx-gray">#{contract.id}</label>
                </div>
            </div>
            <div className="d-flex justify-between mt-15">
                <div className='w-50'>
                    <label className="tx-pink font-bold">Contrato</label><br />
                    <label className="tx-gray font-16">{contract.tipoContrato === 6 ? 'SPIRIT' : 'ASA'}</label>
                </div>
                <div className='w-50'>
                    <label className="tx-pink font-bold">Tipo</label><br />
                    <label className="tx-gray font-16">{contract.duracao} Meses</label>
                </div>
            </div>
            <div className="mt-15">
                    <label className="tx-pink font-bold">Valor Final</label><br />
                    <label className="tx-gray font-16">R${maskCurrency(contract.ValorContratos[0].valorDia)}</label>
            </div>
            <div className="mt-15 d-flex justify-between">
                <div className="w-50">
                    <label className="tx-pink font-bold">Data Finalização</label><br />
                    <label className="tx-gray font-16">{moment(contract.dataFim).format("DD/MM/YYYY")}</label>
                </div>
                {type && 
                    <div className="w-50">
                        <label className="tx-pink font-bold">Alteração de status</label><br />
                        <label className="tx-gray font-16">{moment(contract.updatedAt).format("DD/MM/YYYY")}</label>
                    </div>
                }
            </div>
            {type && (type === 'renewal_contribution' || type === 'renewal_withdraw') ? 
                <div className="d-flex mt-15 justify-between">
                    <div className="w-50">
                        <label className="tx-pink font-bold">Valor {type === 'renewal_contribution' ? 'Aporte' : 'Resgate'}</label><br />
                        <label className="tx-gray font-16">R${type === 'renewal_withdraw' ? maskCurrency(withdrawlValue) : maskCurrency(contributionValue)}</label>
                        
                    </div>
                    <div className="w-50">
                        <label className="tx-pink font-bold">Valor Renovação</label><br />
                        <label className="tx-gray font-16">R${maskCurrency(contract.Renovacaos[0].valorRenovacao)}</label>
                    </div>
                </div>
            : ''}
            <hr />
            {!showOptions ?
                <Button type="primary" onClick={handleOptions} className="mt-15">Editar</Button> :
                <div className="mt-15">
                    <Select onChange={handleRenovationChange} className="card-expired-contract" defaultValue={0}>
                        <Option value={0}>Tipo de renovação</Option>
                        <Option value={11}>Renovação com aporte</Option>
                        <Option value={10}>Renovação com resgate</Option>
                        <Option value={9}>Renovação total</Option>
                        <Option value={8}>Resgate total</Option>
                    </Select>
                    <Select onChange={handleContractType} className="mt-15 card-expired-contract" defaultValue={0}>
                        <Option value={0}>Tipo de contrato</Option>
                        <Option value={15}>4 meses</Option>
                        <Option value={16}>8 meses</Option>
                        <Option value={17}>10 meses</Option>
                        <Option value={18}>12 meses</Option>
                    </Select>
                    <hr />
                    {showInput && 
                        <div className="mt-15">
                            <label className="tx-purple font-bold">Valor {renewalType === 1 ? 'aporte' : 'resgate'}</label>
                            <Input className="card-expired-contract mt-15" onChange={handleAmount} />
                        </div>
                    }
                    {clicked === contract.id && error !== null ? <small className="tx-red">{error}</small> : ''}
                    <Button type="primary" className="mt-15" onClick={() => handleContract(contract.id, contract.Cliente.id, renewalType, contractType, amount, contract.ValorContratos[0].valorDia)}>{isSubmit ? <LoadingOutlined /> : 'Alterar'}</Button>
                </div>
            }
        </Card>
    )
}

export default CardContract