import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { request, getInfoLocalStorage, API_URL } from "../api/config"

const stateFromLocalStorage = getInfoLocalStorage() 

const initialState = {
    isLoading: false,
    success: false,
    message: null,
    contracts: [],
    isSubmit: false,
    contract: {},
    isUpload: false,
    successAction: false
}

export const getContracts = createAsyncThunk(
    'contracts/',
    async (values, thunkAPI) => {
        try {
            const res = await request(`contracts/getContratosPendentes/${values.status}`)
            if(res.code === 51) {
                return ({ contracts: res.contratos })
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao retornar os contratos')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao retornar os contratos')
        }
    }
)

export const getContract = createAsyncThunk(
    'contracts/single',
    async (values, thunkAPI) => {
        try {
            const res = await request(`contracts/getContrato/${values.idContract}`)
            if(res.code === 51) {
                return (res.contrato)
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao retornar os dados do contrato')
            }
        } catch(e) {
            return thunkAPI.rejectWithValue('Houve um erro ao retornar os dados do contrato')
        }
    }
)

export const repproveContract = createAsyncThunk(
    'contracts/repprove',
    async (values, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const res = await request('contracts/confirm', 'PUT', {
                id: values.idContract,
                aprovado: false,
                adminId: id
            })
            if(res.code === 200) {
                return res
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao reprovar o contrato')
        }
    }
)

export const repproveSpirit = createAsyncThunk(
    'contracts/repprove-spirit',
    async (values, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const res = await request('contracts/confirm-spirit', 'PUT', {
                idContrato: values.idContract,
                aprovado: false,
                adminId: id
            })
            if(res.code === 200) {
                return res
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao reprovar o contrato')
        }
    }
)

export const approveContract = createAsyncThunk(
    'contracts/approve',
    async (values, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const res = await request('contracts/confirm', 'PUT', {
                id: values.idContract,
                aprovado: true,
                adminId: id,
                cotacao: values.quota,
                valor: values.initialValue
            })
            if(res.code === 200) {
                return res
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao aprovar o contrato')
        }
    }
)

export const approveSpirit = createAsyncThunk(
    'contracts/approve-spirit',
    async (values, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const res = await request('contracts/confirm-spirit', 'PUT', {
                idContrato: values.idContract,
                cotacao: values.quota,
                adminId: id,
                valor: values.initialValue,
                aprovado: true
            })
            if(res.code === 200) {
                return res
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao aprovar o contrato')
        }
    }
)

export const requestRenewal = createAsyncThunk(
    'contracts/renewal',
    async (values, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const res = await request('contracts/requestContractRenewal', 'POST', {
                idContrato: values.idContract,
                idCliente: values.idClient,
                valor: values.value,
                status: values.status,
                tipoContrato: values.contractType,
                adminId: id
            })
            console.log(res)
            if (res.code === 51) {
                return res
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao solicitar a renovação')
        }
    }
)

export const createOperation = createAsyncThunk(
    'contracts/operation',
    async (values, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const formData = new FormData()
            formData.append('idContrato', values.idContract)
            formData.append('valor', values.value)
            formData.append('tipoTransacao', values.type)
            formData.append('file', values.file)
            formData.append('adminId', id)
            const req = await fetch(API_URL + 'operacional/updateTransacao', {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${getInfoLocalStorage().token}`
                }
            })
            const response = await req.json()
            if(response.code === 51) {
                return response
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao inserir operação')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao inserir a operação')
        }
    }
)

export const uploadContract = createAsyncThunk(
    'contracts/upload',
    async (values, thunkAPI) => {
        try {
            const formData = new FormData()
            formData.append('id', values.idClient)
            formData.append('idContrato', values.idContract)
            formData.append('type', values.type)
            formData.append('tipoContrato', values.contractType)
            formData.append('file', values.file)
            const req = await fetch(API_URL + 'upload', {
                method: 'POST',
                body: formData,
                headers: {
                    'Authorization': `Bearer ${getInfoLocalStorage().token}`
                }
            })
            const response = await req.json()
            if(response.code === 11) {
                return response
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao enviar o contrato')
            }
        } catch (e) {
            console.log(e)
            return thunkAPI.rejectWithValue('Houve um erro ao enviar o contrato')
        }
    }
)

export const validateStatement = createAsyncThunk(
    'contracts/validate-statement',
    async (values, thunkAPI) => {
        try {
            const res = await request('operacional/autenticateExtrato', 'POST', {
                hash: values.hash,
                idContrato: values.idContract
            })
            if(res.code === 51) {
                return res
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Extrato não encontrado')
        }
    }
)

const contractsSlice = createSlice({
    name: 'contracts',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getContracts.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getContracts.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true
                state.contracts = payload.contracts
            })
            .addCase(getContracts.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
            .addCase(repproveContract.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(repproveContract.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(repproveContract.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.message = payload
                state.successAction = false
            })
            .addCase(approveContract.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(approveContract.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(approveContract.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.message = payload
                state.successAction = false
            })
            .addCase(repproveSpirit.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(repproveSpirit.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(repproveSpirit.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.message = payload
                state.successAction = false
            })
            .addCase(approveSpirit.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(approveSpirit.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(approveSpirit.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.message = payload
                state.successAction = false
            })
            .addCase(requestRenewal.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(requestRenewal.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(requestRenewal.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.message = payload
                state.successAction = false
            })
            .addCase(getContract.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getContract.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true
                state.contract = payload
            })
            .addCase(getContract.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
            .addCase(createOperation.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(createOperation.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(createOperation.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.message = payload
                state.successAction = false
            })
            .addCase(uploadContract.pending, (state) => {
                state.isUpload = true
            })
            .addCase(uploadContract.fulfilled, (state) => {
                state.isUpload = false
                state.successAction = true
            })
            .addCase(uploadContract.rejected, (state, { payload }) => {
                state.isUpload = false
                state.message = payload
                state.successAction = false
            })
            .addCase(validateStatement.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(validateStatement.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(validateStatement.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.message = payload
                state.successAction = false
            })
    }
})

const { reducer } = contractsSlice

export default reducer