import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { request, getInfoLocalStorage } from "../api/config"

const stateFromLocalStorage = getInfoLocalStorage() 

const initialState = {
    isLoading: false,
    success: false,
    message: null,
    users: [],
    isSubmit: false,
    user: [],
    successAction: false
}

export const getUsers = createAsyncThunk(
    'users/',
    async (thunkAPI) => {
        try {
            const res = await request('users/')
            if(res.code === 200) {
                return res.clientes
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao retornar os usuários')
        }
    }
)

export const confirmAccess = createAsyncThunk(
    'user/confirm-asset',
    async (idUser, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const res = await request(`users/confirm-asset/${idUser}/${id}`)
            if(res.code === 200) {
                return res
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch(e) {
            return thunkAPI.rejectWithValue('Houve um erro ao confirmar acesso')
        }
    }
)

export const confirmDocument = createAsyncThunk(
    'user/confirm-document',
    async (values, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const res = await request('users/document/', 'PUT', {
                id: values.userId,
                tipoDocumento: values.documentType,
                aprovado: values.approved,
                tipoPessoa: values.personType,
                adminId: id
            })
            if(res.code === 200) {
                return res
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue(`Houve um erro ao ${values.approved ? 'aprovar' : 'reprovar'} documento`)
        }
    }
)

export const getUser = createAsyncThunk(
    'user/',
    async (values, thunkAPI) => {
        try {
            const { code, cliente, message } = await request(`users/${values.idClient}`)
            if(code === 11) {
                return cliente
            } else {
                return thunkAPI.rejectWithValue(message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao retornar as informações do usuário')
        }
    }
)

const usersSlice = createSlice({
    name: 'users',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getUsers.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUsers.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true
                state.users = payload
            })
            .addCase(getUsers.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
            .addCase(confirmAccess.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(confirmAccess.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(confirmAccess.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.successAction = false
                state.message = payload
            })
            .addCase(confirmDocument.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(confirmDocument.fulfilled, (state) => {
                state.isSubmit = false
                state.successAction = true
            })
            .addCase(confirmDocument.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.successAction = false
                state.message = payload
            })
            .addCase(getUser.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getUser.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true
                state.user = payload
            })
            .addCase(getUser.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
    }
})

const { reducer } = usersSlice

export default reducer