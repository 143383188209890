const ButtonTabsComponent = (props) => {
    const { text, notification, active } = props

    return (
        <div className={`btn-tabs ${active && 'btn-tabs-active'}`}>
            <span>{text}</span>
            {notification !== undefined && <div className="btn-tabs-notification" notification={notification} >{notification}</div> }
        </div>
    )
}

export default ButtonTabsComponent