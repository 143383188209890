import { Checkbox } from 'antd'
import './checkbox.less'


const CheckboxComponent = (props) => {
    const { text, checked, index, onChange } = props

    return (
        <div className='checkbox-wrapper'>
            <Checkbox checked={checked} onChange={() => onChange(index)} />
            <label>{text}</label>
        </div>
    )

}

export default CheckboxComponent