import { Button } from 'antd'
import './Button.less'
import Icon from '@ant-design/icons';

const ButtonComponent = (props) => {
    const { type, children, icon, disabled, active, className, onClick } = props
    return (
        <Button
            className={`btn-${type} ${active && 'btn-active'} ${className}`}
            icon={icon ? <Icon component={icon} /> : false}
            type={type}
            disabled={disabled}
            onClick={onClick}
            htmlType="submit"
        >
            {children}
        </Button>
    )

}

export default ButtonComponent