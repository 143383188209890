import { TableComponent as Table } from '../../components'
import moment from 'moment'
import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd'

const ContributionsTable = (props) => {
    const { data } = props
    const columnsTest = [
        {
            title: 'Nome',
            dataIndex: 'Contrato',
            key: 'nome',
            align: 'center',
            render: c => c.Cliente.nome,
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return <>
                        <Input 
                            autoFocus 
                            placeholder="Ex.: João da Silva" 
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => { confirm() }}
                            onBlur={() => { confirm() }}
                        />
                    </>

            },
            filterIcon: () => {
                return <SearchOutlined />
            },
            onFilter: (value, record) => {
                return record.Contrato.Cliente.nome.toLowerCase().includes(value.toLowerCase())
            },
        },
        {
            title: 'Contrato',
            dataIndex: 'Contrato',
            key: 'tipoContrato',
            align: 'center',
            render: c => c.tipoContrato === 6 ? 'SPIRIT' : 'ASA',
            filters: [
                { text: 'SPIRIT', value: [6] },
                { text: 'ASA', value: [1,2,3,4,5,7,8,9,10,11,12,13,14]}
            ],
            onFilter: (value, record) => value.indexOf(record.Contrato.tipoContrato) != -1
        },
        {
            title: 'Valor',
            dataIndex: 'valor',
            key: 'value',
            align: 'center',
            render: v => 'R$ '+v,
            sorter: (a, b) => a.valor - b.valor,
        },
        {
            title: 'Data solicitação',
            dataIndex: 'createdAt',
            key: 'date_solicitation',
            align: 'center',
            render: date => moment(date).format("DD/MM/YYYY")
        },
        {
            title: 'Data aprovação',
            dataIndex: 'dataAprovacao',
            key: 'date_approval',
            align: 'center',
            render: date => moment(date).format("DD/MM/YYYY")
        },
        {
            title: 'Data cotização',
            dataIndex: 'dataOperacao',
            key: 'date_quota',
            align: 'center',
            render: date => moment(date).format("DD/MM/YYYY")
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            render: status => status == 2 ? 'Negado' : 'Aprovado',
            filters: [
                { text: 'Aprovado', value: [1,3] },
                { text: 'Negado', value: [2]}
            ],
            onFilter: (value, record) => value.indexOf(record.status) != -1
        }
    ]
    return (
        <Table column={columnsTest} data={data} />
    )
}

export default ContributionsTable