import React from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import { Components, Users, UsersPending, UsersProfile, NewContract, ContractDetails, ExpiredContracts, UsersReport, Monitor, ApprovalsContributions, ApprovalsWithdrawls, ApprovalsContracts, ApprovalsSpirits, Login, Operations, Wallet, WalletTokens } from '../pages'
import { store } from '../redux/store'
import { Provider } from 'react-redux'

const RouterWrapper = () => {
    return (
        <Router>
            <Provider store={store}>
                <Routes>
                    <Route exact sensitive path="/" element={<Navigate to="/login" />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/components" element={<Components />} />
                    <Route path="/clientes/geral" element={<Users />} />
                    <Route path="/clientes/pendentes" element={<UsersPending />} />
                    <Route path="/cliente/:id" element={<UsersProfile />} />
                    <Route path="/contrato/:id" element={<ContractDetails />} />
                    <Route path="/cliente/:id/novo-contrato/" element={<NewContract />} />
                    <Route path="/clientes/relatorio/" element={<UsersReport />} />
                    <Route path="/contratos/vencidos/" element={<ExpiredContracts />} />
                    <Route path="/monitor" element={<Monitor />} />
                    <Route path="/aprovacoes/aportes" element={<ApprovalsContributions />} />
                    <Route path="/aprovacoes/resgates" element={<ApprovalsWithdrawls />} />
                    <Route path="/aprovacoes/asa" element={<ApprovalsContracts />} />
                    <Route path="/aprovacoes/spirit" element={<ApprovalsSpirits />} />
                    <Route path="/operacional/:id" element={<Operations />} />
                    <Route path="/wallet/conversoes" element={<Wallet />} />
                    <Route path="/wallet/tokens-aprovacao" element={<WalletTokens />} />
                </Routes>
            </Provider>
        </Router>
    )
}

export default RouterWrapper