import React, { useState } from 'react'
import { Card, Row, Col, Alert, Modal } from 'antd'
import { ButtonComponent as Button } from '../../components'
import { ReactComponent as iconDownload } from '../../assets/img/download.svg'
import { ReactComponent as iconCheck } from '../../assets/img/check.svg'
import { ReactComponent as iconEye } from '../../assets/img/eye.svg'
import { ReactComponent as iconError } from '../../assets/img/error.svg'
import { downloadFile } from '../../utils/functions'
import { useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import ModalDocs from './modalDocs'

const CardUserKyc = (props) => {

    const { user, clickedCard, confirmDocument, approved, documentType } = props
    const { isSubmit, successAction } = useSelector(state => state.users)
    const [openModal, setOpenModal] = useState(false)
    const [type, setType] = useState(1)

    const handleModal = (type) => {
        const aux = openModal
        setOpenModal(!aux)
        setType(type)
    }

    const closeModal = () => {
        setOpenModal(false)
    }

    const handleType = (type) => {
        setType(type)
    }

    const handleConfirm = (type, approved) => {
        const { user } = props
        closeModal()
        confirmDocument(user.id, type, approved, user.tipoPessoa)
    }

    return (
        <>
        <Card className={`card-kyc ${successAction && clickedCard === user.id && !isSubmit ? approved ? 'card-success' : 'card-reproved' : '' }`}>
            {successAction && clickedCard === user.id && !isSubmit ? approved ?
            <div className="d-flex align-center justify-center flex-column">
                <Icon component={iconCheck} />
                <h1 className="tx-white font-bold mt-15 font-22 text-center">{documentType === 1 ? 'Documento de identidade' : 'Comprovante de residência'} Aprovado</h1>
            </div> :
            <div className="d-flex align-center justify-center flex-column">
                <Icon component={iconError} />
                <h1 className="tx-white font-bold mt-15 font-22 text-center">{documentType === 1 ? 'Documento de identidade' : 'Comprovante de residência'} Reprovado</h1>
            </div> :
            <>
                <h4 className="tx-pink card-title">{user.nome}</h4>
                <hr />
                <label className="tx-gray font-bold">Identidade</label>
                <Button icon={iconDownload} onClick={() => downloadFile(user.documento)} type="primary" className="mt-10" disabled={user.documento === null && true}>Download</Button>
                <Button icon={iconEye} type="primary" className="mt-15" disabled={user.documento === null && true} onClick={() => handleModal(1)}>Visualizar</Button>
                {user.nivelVerificacao === '011' ? <Alert className="mt-15" type="success" showIcon message="Documento de identidade aprovado" /> :
                user.documento === null ? <Alert type="warning" className="mt-15" showIcon message="Documento de identidade não enviado" /> :
                <Row className="mt-15">
                    <Col md={12} className="pr-5">
                        <Button disabled={isSubmit} type="danger" onClick={() => handleConfirm(1, false)}>{isSubmit && clickedCard === user.id && documentType === 1 && !approved ? <LoadingOutlined /> : "Reprovar"}</Button>
                    </Col>
                    <Col md={12} className="pl-5">
                        <Button disabled={isSubmit} type="success" onClick={() => handleConfirm(1, true)}>{isSubmit && clickedCard === user.id && documentType === 1 && approved ? <LoadingOutlined /> : "Aprovar"}</Button>
                    </Col>
                </Row>}
                <hr />
                <label className="tx-gray font-bold mt-15">Comprovante de Residência</label>
                <Button icon={iconDownload} onClick={() => downloadFile(user.comprovanteEndereco)} type="primary" className="mt-10" disabled={user.comprovanteEndereco === null ? true : false}>Download</Button>
                <Button icon={iconEye} type="primary" className="mt-15" onClick={() => handleModal(2)} disabled={user.comprovanteEndereco === null ? true : false}>Visualizar</Button>
                {user.nivelVerificacao === '101' ? <Alert type="success" className="mt-15" showIcon message="Comprovante de residência aprovado" /> :
                user.comprovanteEndereco === null ? <Alert type="warning" className="mt-15" showIcon message="Compravante de residência não enviado" /> :
                <Row className="mt-15">
                    <Col md={12} className="pr-5">
                        <Button disabled={isSubmit} type="danger" onClick={() => handleConfirm(2, false)}>{isSubmit && clickedCard === user.id && documentType === 2 && !approved ? <LoadingOutlined /> : "Reprovar"}</Button>
                    </Col>
                    <Col md={12} className="pl-5">
                        <Button disabled={isSubmit} type="success" onClick={() => handleConfirm(2, true)}>{isSubmit && clickedCard === user.id && documentType === 2 && approved ? <LoadingOutlined /> : "Aprovar"}</Button>
                    </Col>
                </Row>}
            </> }
        </Card>

        <Modal
            visible={openModal}
            width="95vw"
            centered
            footer={null}
            closable={false}
            maskClosable={false}
            className="modal-docs"
        >
            <ModalDocs user={user} type={type} handleType={handleType} closeModal={closeModal} handleConfirm={handleConfirm} />
        </Modal>
        </>
    )
}

export default CardUserKyc