import React from 'react'
import './sidebar.less'
import { Layout, Image } from 'antd'
import { ButtonMenuComponent as ButtonMenu } from '../../components'
import logo from '../../assets/img/logo.png'
import { ReactComponent as iconUsers } from '../../assets/img/people.svg'
import { ReactComponent as iconApprovals } from '../../assets/img/chart-success.svg'
import { ReactComponent as iconExpired } from '../../assets/img/archive.svg'
import { ReactComponent as iconMonitor } from '../../assets/img/monitor.svg'
import { ReactComponent as iconWallet } from '../../assets/img/wallet.svg'
import Luiza from '../../assets/img/luiza.png'
import Victor from '../../assets/img/victor.png'
import Pedro from '../../assets/img/pedro.png'
import Gabriel from '../../assets/img/gabriel.png'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import { useEffect } from 'react'

const { Sider } = Layout

const menu = [
    {
        title: 'Clientes',
        key: 'cliente',
        icon: iconUsers,
        link: '/clientes/geral'
    }, {
        title: 'Aprovações',
        key: 'aprovacoes',
        icon: iconApprovals,
        link: '/aprovacoes/aportes'
    }, {
        title: 'Vencidos',
        key: 'vencidos',
        icon: iconExpired,
        link: '/contratos/vencidos'
    }, {
        title: 'Wallet',
        key: 'wallet',
        icon: iconWallet,
        link: '/wallet/conversoes'
    }
]

const users = {
    7: {
        job: 'CEO',
        img: Victor,
        name: 'Victor Freire'
    },
    6: {
        job: 'CSO',
        img: Gabriel,
        name: 'Gabriel Fischer'
    },
}

const SidebarComponent = () => {

    const location = useLocation()
    const path = location.pathname
    const { id } = useSelector(state => state.login)
    const { isLoadingNotifications, notifications } = useSelector(state => state.monitors)
    const [isLoading, setIsLoading] = useState(true)
    const [userId, setUserId] = useState(id)

    const returnNotifications = (type) => {
        if(type === 'aprovacoes') {
            return parseInt(notifications.aportes) + parseInt(notifications.resgates) + parseInt(notifications.spirit) + parseInt(notifications.asa)
        } else if(type === 'vencidos') {
            return parseInt(notifications.vencidos)
        } else if(type === 'wallet') {
            return parseInt(notifications.tokens)
        } else if(type === 'cliente') {
            return parseInt(notifications.clientes) + parseInt(notifications.acessos)
        }
        else {
            return undefined
        }
    }

    useEffect(() => {
        if(userId === null) {
            setUserId(location.state.id)
        } else {
            setIsLoading(false)
        }
    }, [location, userId])

    useEffect(() => {
        setIsLoading(false)
    }, [userId])

    return (
        !isLoading &&
        <Sider className="p-2" width="22vw"
        style={{
            overflow: 'auto',
            height: '100vh',
            position: 'fixed',
            left: 0,
            top: 0,
            bottom: 0,
          }}
        >
            <div className="sider-wrapper">
                <div className="user-logged">
                    <div>
                        <Image className="user-image" src={users[userId].img} preview={false} />
                    </div>
                    <div>
                        <h3 className="title">{users[userId].name}</h3>
                        <label className="tx-gray">{users[userId].job} | Admin</label>
                    </div>
                </div>
                <div className="sider-menu">
                    {menu.map((i, p) => (
                        <Link className={p != 0 && "mt-15"} to={i.link}><ButtonMenu icon={i.icon} key={i.key} notification={isLoadingNotifications ? undefined : returnNotifications(i.key)} active={path.includes(i.key) ? true : false}>{i.title}</ButtonMenu></Link>
                    ))}
                </div>
                <Link to="/monitor"><ButtonMenu icon={iconMonitor} active={path === "/monitor" ? true : false}>Monitor</ButtonMenu></Link>
                <div className="sider-footer">
                    <Image src={logo} preview={false} className="logo-footer" />
                    <label className="tx-placeholder">Admin v2.0.0</label>
                </div>
            </div>
        </Sider>
    )
}

export default SidebarComponent