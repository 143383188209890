import { Row, Col, Input, Upload, message as alert } from 'antd'
import { ButtonComponent as Button } from '../../components'
import { useState } from 'react'
import { ReactComponent as iconUpload } from '../../assets/img/upload.svg'
import Icon from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux'
import { createOperation } from '../../redux/slices/contractsSlice'
import { LoadingOutlined } from '@ant-design/icons';

const { Dragger } = Upload

const CreateOperation = (props) => {
    const { type, handleModal, contract } = props
    const { isSubmit } = useSelector(state => state.contracts)
    const [value, setValue] = useState(0)
    const [file, setFile] = useState(null)
    const [error, setError] = useState(null)
    const dispatch = useDispatch()

    const handleValue = (e) => {
        setValue(e.target.value)
    }

    const beforeUpload = file => {
        setFile(file)
        return false
    }

    const verifyValues = () => {
        if(value === 0) {
            return setError(`O valor do ${type === 'contribution' ? 'aporte' : 'resgate'} deve ser maior que R$0`)
        }
        if(file === null && type === 'contribution') {
            return setError('O comprovante é obrigatório')
        }
        onSubmit()
    }

    const onSubmit = () => {
        const data = { idContract: contract.id, value, file, type: type === 'contribution' ? 'Aporte' : 'Resgate' }
        dispatch(createOperation(data)).then((res) => {
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                setValue(0)
                setFile(null)
                alert.success(type === 'contribution' ? 'Aporte criado com sucesso' : 'Resgate criado com sucesso')
            }
        })
    }

    return (
        <>
        <Row>
            <Col md={20} className="d-flex align-center">
                <h1 className="font-bold font-28 tx-white">{type === 'contribution' ? 'Novo aporte' : 'Novo resgate'}</h1>                
            </Col>
            <Col md={4} className="d-flex align-center justify-end">
                <label className="tx-white btn-close" onClick={() => handleModal(type)}>Cancelar</label>
            </Col>
        </Row>
        <Row className="deny-info-wrapper d-flex align-items-center">
            <Col md={24} className="pl-20">
                <Row>
                    <Col md={20}>
                        <h3 className="tx-pink font-bold">{contract.Cliente.nome}</h3>
                    </Col>
                    <Col md={4}>
                        <label className="tx-pink font-bold">{contract.tipoContrato == 6 ? "SPIRIT" : "ASA"}</label><br />
                        <label className="tx-gray font-16">#{contract.id}</label>
                    </Col>
                    <Col md={24}>
                        <hr />
                    </Col>
                </Row>
                <label className="tx-pink font-bold font-16 mt-30">Valor do {type === 'contribution' ? 'aporte' : 'resgate'}</label>
                <Input className="mt-15 bg-white" value={value} onChange={handleValue} disabled={isSubmit} />
                {error !== null && <small className="tx-red">{error}</small>}
                {type === 'contribution' &&
                <Dragger accept=".png, .jpg, .pdf" height={100} className="mt-10" beforeUpload={beforeUpload} onRemove={() => setFile(null)}>
                    <div className="d-flex justify-center align-center">
                        <Icon component={iconUpload} className="font-22" />
                        <label className="tx-gray font-bold ml-5">Clique ou arraste o arquivo aqui</label>
                    </div>
                </Dragger>}
                <Row className="mt-15">
                    <Col md={24}>
                        <Button onClick={verifyValues} type='primary' active>{isSubmit ? <LoadingOutlined /> : `Inserir ${type === 'contribution' ? 'aporte' : 'resgate'}`}</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
    )
}

export default CreateOperation