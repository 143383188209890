import React, { Fragment } from 'react'
import { ButtonComponent as Button, TableComponent as Table, ButtonMenuComponent as ButtonMenu, CheckboxComponent as Checkbox } from '../components'
import '../assets/theme/theme.less'
import { Select, Input } from 'antd'
import { ReactComponent as iconUsers } from '../assets/img/people.svg'
import { ReactComponent as iconApprovals } from '../assets/img/chart-success.svg'
import { ReactComponent as iconExpired } from '../assets/img/archive.svg'
import { ReactComponent as iconDownload } from '../assets/img/download.svg'
import { ReactComponent as iconChevronLeft } from '../assets/img/chevron-left.svg'
import { ReactComponent as iconAdd } from '../assets/img/add.svg'

const { Option } = Select;

const Components = () => {

    return (
        <Fragment>
            <div style={{ margin: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F8F8FF', padding: '30px' }}>
                <h1>Buttons</h1>
                <Button icon={iconDownload} type="primary">Download</Button><br />
                <Button type="danger">Reprovar</Button><br />
                <Button type="success">Aprovar</Button><br />
                <Button icon={iconChevronLeft} type="back">Voltar</Button><br />
                <br />
            </div>
            <div style={{ margin: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '30px' }}>
                <h1>Buttons fundo branco</h1>
                <Button type="document">Identidade</Button><br />
                <Button icon={iconAdd} type="action">Adicionar Contrato</Button><br />
                <Button type="filter">SPIRIT</Button><br />
                <br />
            </div>

            <div style={{ paddingTop: '30px' }}>
                <h1 style={{ textAlign: 'center' }}>Tabela</h1>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Table />
                </div>
                <br />
            </div>
            <br />
            <div style={{ paddingTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: '#F8F8FF', padding: '30px' }}>
            <h1>Botões do Menu Lateral</h1>
            <ButtonMenu active icon={iconUsers} notification={'100'}>Clientes</ButtonMenu><br />
            <ButtonMenu icon={iconApprovals} notification={'100'}>Aprovações</ButtonMenu><br />
            <ButtonMenu icon={iconExpired} notification={'100'}>Vencidos</ButtonMenu>
                <br />
            </div>
            <br />
            <div style={{ paddingTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '30px' }}>
            <h1>Select dropdown</h1>
            <Select>
                <Option value="teste 1">Teste 1</Option>
                <Option value="teste 2">Teste 2</Option>
                <Option value="teste 3">Teste 3</Option>
                <Option value="teste 4">Teste 4</Option>
                <Option value="teste 5">Teste 5</Option>
            </Select>
                <br />
            </div>
            <div style={{ paddingTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '30px',backgroundColor: '#F8F8FF' }}>
            <h1>Input</h1>
            <Input type="text" placeholder="Digite aqui o valor" />
                <br />
            </div>
            <div style={{ paddingTop: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '30px',backgroundColor: '#F8F8FF' }}>
            <h1>Checkbox</h1>
                <Checkbox text="Nome" />
                <br />
            </div>
        </Fragment>
    )
}

export default Components