import React, { useEffect } from 'react'
import './layout.less'
import { Layout } from 'antd'
import { SidebarComponent as Sidebar } from '../../components'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getNotifications } from '../../redux/slices/monitorsSlice'
import { useDispatch } from 'react-redux'

const { Content } = Layout

const LayoutComponent = (props) => {
    const { children, width } = props
    const { isAuth } = useSelector(state => state.login)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getNotifications())
    }, [dispatch])

    return (
        !isAuth ? <Navigate to="/login" /> :
        <Layout className="h-100">
            <Sidebar />
            <Layout>
                <Content className="content-p" style={{width: width ? '76.5vw' : '70vw'}}>
                    {children}
                </Content>
            </Layout>
        </Layout>
    )
}

export default LayoutComponent