import axios from "axios";

export const LOCAL_STORAGE_KEY = '337E35C2C6F7FE0516DBD3679C7E735DEA645F48CF971354A0D1FF9474AD403B'

export const API_URL = process.env.REACT_APP_API_URL

export const DOCS_URL = process.env.REACT_APP_DOCS_URL

export const request = async (url, method = 'GET', body) => {
    const { token } = getInfoLocalStorage();
    const headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
    if (token) {
        headers.Authorization = `Bearer ${token}`
    }

    const config = {
        method,
        url: API_URL + url,
        headers,
        data: body === undefined ? undefined : JSON.stringify(body)
    }

    const response = await axios(config)
    const result = response.data
    return result
}

export const getInfoLocalStorage = () => (
    JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {}
)

export const setInfoLocalStorage = (data) => {
    const infoFromLocalStorage = getInfoLocalStorage()
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify({
        ...infoFromLocalStorage,
        ...data
    }))
}

export const destroyInfoLocalStorage = () => (
    localStorage.removeItem(LOCAL_STORAGE_KEY)
)