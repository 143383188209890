import React from 'react';
import RouterWrapper from './routes'
import "./assets/theme/theme.css";

const App = ()  => {
  
  return (
        <RouterWrapper/>
  )
}

export default App;