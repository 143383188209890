import React from 'react'
import { Row, Col, Image } from 'antd'
import profile from '../../assets/img/profile.png'
import { UserWrapper } from '../../components'
import { maskCep } from '../../utils/functions'

const UserInfo = (props) => {
    const { user, isLoading, success } = props
    return (
        <>
            {isLoading ? 'Carregando...' : !success ? 'Houve um erro ao carregar as informações' :
            <>
            <Row id="dados">
                <Col md={4}>
                    <Image src={profile} preview={false} className="profile-pic" />
                </Col>
                <Col md={20}>
                    <UserWrapper user={user} />
                </Col>
            </Row>
            <Row id="residencia" className="mt-25">
                <Col md={4}>
                    <label className="tx-placeholder font-bold">Residência</label>
                </Col>
                <Col md={20} className="d-flex flex-column">
                    <hr className="profile-divisor" />
                    <div className="d-flex justify-between mt-25 pl-20 pr-20">
                        <div className="w-50">
                            <label className="tx-pink font-bold">Endereço</label><br />
                            <label className="tx-gray">{user.endereco}</label>
                        </div>
                        <div className="w-25">
                            <label className="tx-pink font-bold">Número</label><br />
                            <label className="tx-gray">{user.numero}</label>
                        </div>
                        <div className="w-25">
                            <label className="tx-pink font-bold">Complemento</label><br />
                            <label className="tx-gray">{user.complemento}</label>
                        </div>
                    </div>
                    <div className="d-flex justify-between mt-30 pl-20 pr-20">
                        <div className="w-50">
                            <label className="tx-pink font-bold">Cidade/Estado</label><br />
                            <label className="tx-gray">{user.cidade} - {user.estado}</label>
                        </div>
                        <div className="w-50">
                            <label className="tx-pink font-bold">CEP</label><br />
                            <label className="tx-gray">{maskCep(user.cep)}</label>
                        </div>
                    </div>
                </Col>
            </Row>
            </>}
        </>
    )
}

export default UserInfo