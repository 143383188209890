import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { request, setInfoLocalStorage, getInfoLocalStorage } from "../api/config"

const stateFromLocalStorage = getInfoLocalStorage() 

const initialState = {
    isLoading: false,
    success: false,
    message: null,
    isAuth: stateFromLocalStorage.isAuth || false,
    name: stateFromLocalStorage.name || null,
    id: stateFromLocalStorage.id || null,
    type: stateFromLocalStorage.type || null
}

export const onLoginFinish = createAsyncThunk(
    'users/admin',
    async ({ email, password }, thunkAPI) => {
        try {
            const res = await request('users/admin', 'POST', {
                username: email,
                password: password
            })
            if(res.code === 200) {
                const userInfo = { token: res.token, isAuth: true, id: res.id, type: res.tipo }
                setInfoLocalStorage(userInfo)
                return ({ name: res.nome, id: res.id })
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            if(e.response.data.code === 403)
                return thunkAPI.rejectWithValue('Acesso Negado')
            else
                console.log(e.message)
                return thunkAPI.rejectWithValue('Houve um erro ao realizar login')
        }
    }
)

const loginSlice = createSlice({
    name: 'login',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(onLoginFinish.pending, (state) => {
                state.isLoading = true
            })
            .addCase(onLoginFinish.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.isAuth = true
                state.success = true
                state.name = payload.name
                state.id = payload.id
            })
            .addCase(onLoginFinish.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
    }
})

const { reducer } = loginSlice

export default reducer

