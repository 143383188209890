import './userWrapper.less';
import moment from 'moment'
import { maskCpf, maskTelefone } from '../../utils/functions'

const UserWrapper = (props) => {
    const { user } = props
    return (
        <div className="user-info-wrapper">
            <div className="d-flex">
                <div className="w-50">
                    <label className="tx-pink font-bold">Nome</label><br />
                    <label className="tx-gray">{user.nome}</label>
                </div>
                <div className="w-25">
                    <label className="tx-pink font-bold">Data de Nascimento</label><br />
                    <label className="tx-gray">{moment(user.nascimento).format("DD/MM/YYYY")}</label>
                </div>
                <div className="w-25">
                    <label className="tx-pink font-bold">Data de Cadastro</label><br />
                    <label className="tx-gray">{moment(user.createdAt).format("DD/MM/YYYY")}</label>
                </div>
            </div>
            <div className="d-flex mt-25">
                <div className="w-25">
                    <label className="tx-pink font-bold">CPF</label><br />
                    <label className="tx-gray">{maskCpf(user.cpf)}</label>
                </div>
                <div className="w-25">
                    <label className="tx-pink font-bold">RG</label><br />
                    <label className="tx-gray">{user.rg}</label>
                </div>
            </div>
            <div className="d-flex w-100 mt-25">
                <div className="w-75">
                    <label className="tx-pink font-bold">E-mail</label><br />
                    <label className="tx-gray">{user.email}</label>
                </div>
                <div className="w-50">
                    <label className="tx-pink font-bold">Telefone</label><br />
                    <label className="tx-gray">{maskTelefone(user.telefone)}</label>
                </div>
            </div>
        </div>
    )
}

export default UserWrapper

                    