import { Row, Col, Input, message as alert, } from 'antd'
import { ButtonComponent as Button } from '../../components'
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons';
import { getFee, changeFee } from '../../redux/slices/walletSlice';

const AnualFee = (props) => {
    const { handleModal } = props
    const dispatch = useDispatch()
    const { isLoadingFee, anualFee, isSubmit } = useSelector(state => state.wallets)
    const [showInput, setShowInput] = useState(false)
    const [fee, setFee] = useState()
    const [error, setError] = useState(false)

    const handleInput = () => {
        var aux = showInput
        setShowInput(!aux)
    }

    const handleChange = (e) => {
        setFee(e.target.value)
    }

    const handleSubmit = () => {
        if(fee === undefined) {
            return setError(true)
        }
        const data = { fee }
        dispatch(changeFee(data)).then((res) => {
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                alert.success("Taxa alterada com sucesso")
                handleInput()
                dispatch(getFee())
            }
        })
    }

    useEffect(() => {
        dispatch(getFee())
    }, [dispatch])

    return (
        <>
        <Row>
            <Col md={20} className="d-flex align-center">
                <h1 className="font-bold font-28 tx-white">Taxa Anual</h1>                
            </Col>
            <Col md={4} className="d-flex align-center justify-end">
                <label className="tx-white btn-close" onClick={handleModal}>Cancelar</label>
            </Col>
        </Row>
        <Row className="deny-info-wrapper d-flex align-items-center">
            <Col md={12} className="pl-20">
                <label className="tx-pink font-bold font-16">Taxa atual</label><br />
                <label className="tx-gray font-28 font-bold">{isLoadingFee ? <LoadingOutlined /> : anualFee+" %"}</label>
            </Col>
            <Col md={12} className="d-flex justify-end">
                <Button className="w-50" type="primary" onClick={handleInput}>{showInput ? 'Cancelar' : 'Alterar taxa'}</Button>
            </Col>
            <Col md={4}></Col>
            <Col className="mt-30" md={16}>
                {showInput && 
                    <>
                        <Input type="number" className='bg-white' placeholder="Nova taxa" onChange={handleChange} />
                        {error && <small className='tx-red'>Digite uma taxa válida</small>}
                        <Button className="mt-10" type="primary" onClick={handleSubmit}>{isSubmit ? <LoadingOutlined /> : 'Alterar'}</Button>
                    </>
                }
            </Col>
        </Row>
        </>
    )
}

export default AnualFee