import React, { useEffect, useState } from 'react'
import '../../assets/theme/theme.less'
import { LayoutComponent as Layout, TabsMenuComponent as TabsMenu, TitleTabs as Title } from '../../components'
import { Row, Col, Tabs, List, message as alert } from 'antd'
import CardWithdrawls from './cardWithdrawls'
import WithdrawlsTable from './withdrawlsTable'
import { useDispatch, useSelector } from 'react-redux'
import { getWithdrawls, denyWithdrawl, approveWithdrawl, quoteWithdrawl, transferWithdrawl } from '../../redux/slices/withdrawlsSlice'

const { TabPane } = Tabs

const ApprovalsWithdrawls = () => {
    const { isLoading, message, success, withdrawls, isSubmit, successAction } = useSelector(state => state.withdrawls)
    const [pending, setPending] = useState([])
    const [quota, setQuota] = useState([])
    const [transfer, setTransfer] = useState([])
    const [history, setHistory] = useState([])
    const [approved, setApproved] = useState(false)
    const [clicked, setClicked] = useState(0)

    const dispatch = useDispatch()

    const deny = (reason, idWithdrawl, approved) => {
        setClicked(idWithdrawl)
        setApproved(approved)
        const data = { reason, idWithdrawl }
        dispatch(denyWithdrawl(data)).then((res) => {
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                setTimeout(() => {
                    dispatch(getWithdrawls())
                }, 1000)
            }
        })
    }

    const approve = (idWithdrawl, idContract, approved) => {
        setClicked(idWithdrawl)
        setApproved(approved)
        const data = { idWithdrawl, idContract }
        dispatch(approveWithdrawl(data)).then((res) => {
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                setTimeout(() => {
                    dispatch(getWithdrawls())
                    setClicked(0)
                }, 1000)
            }
        })
    }

    const quote = (quota, idWithdrawl, idContract, approved) => {
        setClicked(idWithdrawl)
        setApproved(approved)
        const data = { quota, idWithdrawl, idContract }
        dispatch(quoteWithdrawl(data)).then((res) => {
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                setTimeout(() => {
                    dispatch(getWithdrawls())
                    setClicked(0)
                }, 1000)
            }
        })
    }

    const onTransfer = (idContract, idWithdrawl, file) => {
        setClicked(idWithdrawl)
        setApproved(true)
        const data = { idContract, idWithdrawl, file }
        dispatch(transferWithdrawl(data)).then((res) => {
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                setTimeout(() => {
                    dispatch(getWithdrawls())
                    setClicked(0)
                }, 1000)
            }
        })
    }

    useEffect(() => {
        dispatch(getWithdrawls())
    }, [dispatch])

    useEffect(() => {
        var copy = withdrawls
        if(withdrawls && withdrawls.length > 0) {
            var p = copy.filter(c => c.status == 0)
            var q = copy.filter(c => c.status == 1)
            var t = copy.filter(c => c.status == 2)
            var h = copy.filter(c => c.status == 3 || c.status == 4) 
            h = h.sort((a, b) => new Date(b.dataSolicitacao) - new Date(a.dataSolicitacao))
            setPending([...p])
            setQuota([...q])
            setTransfer([...t])
            setHistory([...h])
        }
    }, [withdrawls])

    return (
        <Layout width>
            <Row>
                <Col md={24}>
                    <h1 className="title page-title">Aprovações</h1>
                </Col>
                <Col md={24} className="mt-15 d-flex align-center">
                    <div className="d-flex align-center m-auto">
                        <TabsMenu type="approvals" />
                    </div>
                </Col>
                <Col md={24} className="mt-15">
                    <Tabs defaultActiveKey="1" centered>
                        <TabPane tab={<Title title="Pendentes" notification={pending.length} />} key="1">
                            <Row className="mt-25">
                                {isLoading ? 'Carregando' : success ?
                                    <List
                                        grid={{ gutter: 16, column: 3}}
                                        dataSource={pending}
                                        pagination
                                        renderItem={item => (
                                            <List.Item>
                                                <CardWithdrawls status="pending" withdrawl={item} isSubmit={isSubmit} success={successAction} message={message} approved={approved} clicked={clicked} deny={deny} approve={approve} />
                                            </List.Item>
                                        )}
                                    /> : 'Houve um erro ao carregar os resgates'
                                }
                            </Row>
                        </TabPane>
                        <TabPane tab={<Title title="Cotização" notification={quota.length} />} key="2">
                            <Row className="mt-25">
                                {isLoading ? 'Carregando' : success ?
                                    <List
                                        grid={{ gutter: 16, column: 3}}
                                        dataSource={quota}
                                        pagination
                                        renderItem={item => (
                                            <List.Item>
                                                <CardWithdrawls status="quota" withdrawl={item} isSubmit={isSubmit} success={successAction} message={message} approved={approved} clicked={clicked} quote={quote} deny={deny} approve={approve} />
                                            </List.Item>
                                        )}
                                    /> : 'Houve um erro ao carregar os resgates'
                                }
                            </Row>
                        </TabPane>
                        <TabPane tab={<Title title="Transferência" notification={transfer.length} />} key="3">
                            <Row gutter={[15,15]} className="mt-25">
                                {isLoading ? 'Carregando' : success ?
                                    <List
                                        grid={{ gutter: 16, column: 3}}
                                        dataSource={transfer}
                                        pagination
                                        renderItem={item => (
                                            <List.Item>
                                                <CardWithdrawls status="transfer" withdrawl={item} clicked={clicked} onTransfer={onTransfer} approved={approved} isSubmit={isSubmit} success={successAction} />
                                            </List.Item>
                                        )}
                                    /> : 'Houve um erro ao carregar os resgates'
                                }
                            </Row>
                        </TabPane>
                        <TabPane tab={<Title title="Histórico" />} key="4">
                            <Row gutter={[15,15]} className="mt-25">
                                <Col md={24}>
                                    <WithdrawlsTable data={history} />
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </Layout>
    )
}

export default ApprovalsWithdrawls