import { TableComponent as Table } from '../../components'
import moment from 'moment'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons';
import { maskCpf, maskTelefone } from '../../utils/functions';

const UsersTable = (props) => {
    const { users, loading } = props
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: '20%',
            sorter: {
                compare: (a, b) => a.id - b.id,
                multiple: 1,
            },
        },
        {
            title: 'Nome',
            dataIndex: 'nome',
            key: 'nome',
            align: 'center', 
            width: '30%',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return <>
                        <Input 
                            autoFocus 
                            placeholder="Ex.: João da Silva" 
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => { confirm() }}
                            onBlur={() => { confirm() }}
                        />
                    </>

            },
            filterIcon: () => {
                return <SearchOutlined />
            },
            onFilter: (value, record) => {
                return record.nome.toLowerCase().includes(value.toLowerCase())
            },
        },
        {
            title: 'E-mail',
            dataIndex: 'email',
            key: 'email',
            align: 'center',
            width: '30%',
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
                return <>
                        <Input 
                            autoFocus 
                            placeholder="Ex.: joao@gmail" 
                            onChange={(e) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : []);
                                confirm({ closeDropdown: false });
                            }}
                            onPressEnter={() => { confirm() }}
                            onBlur={() => { confirm() }}
                        />
                    </>

            },
            filterIcon: () => {
                return <SearchOutlined />
            },
            onFilter: (value, record) => {
                return record.email.toLowerCase().includes(value.toLowerCase())
            },
        },
        {
            title: 'RG',
            dataIndex: 'rg',
            key: 'rg',
            align: 'center',
            width: '15%',
        },
        {
            title: 'CPF',
            dataIndex: 'cpf',
            key: 'cpf',
            align: 'center',
            width: '30%',
            render: value => maskCpf(value)
        },
        {
            title: 'Telefone',
            dataIndex: 'telefone',
            key: 'telefone',
            align: 'center',
            width: '30%',
            render: value => maskTelefone(value)
        },
        {
            title: 'Nascimento',
            dataIndex: 'nascimento',
            key: 'nascimento',
            align: 'center',
            width: '30%',
            render: value => moment(value).format("DD/MM/YYYY")
        },
        {
            title: 'Cadastro',
            dataIndex: 'createdAt',
            key: 'createdAt',
            align: 'center',
            width: '30%',
            render: value => moment(value).format("DD/MM/YYYY")
        }
    ]
    return (
        <Table 
            loading={loading}
            column={columns} 
            data={users} 
            layout="fixed"
        />
    )
}

export default UsersTable