import { Table } from 'antd'
import './table.less'

const TableComponent = (props) => {
    const { column, data, loading, layout, id } = props
    return (
        <Table
            loading={loading}
            columns={column}
            dataSource={data}
            size={'medium'}
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-dark' :  'table-row-light'}
            onRow={(record, index) => {
                return {
                    onClick: e => record.id ? id === 'monitor' ? document.location.href = `/cliente/${record.idCliente}` : document.location.href = `/cliente/${record.id}` : console.log('nenhum lugar para ir')
                }
            }}
            tableLayout={layout}
        />

    )

}

export default TableComponent