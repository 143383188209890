import React from 'react'
import '../../assets/theme/theme.less'
import { LayoutComponent as Layout, TableComponent as Table } from '../../components'
import { Row, Col } from 'antd'
import { ReactComponent as iconError } from '../../assets/img/error-red.svg'
import { ReactComponent as iconSuccess } from '../../assets/img/success-green.svg'
import Icon from '@ant-design/icons';
import moment from 'moment'
import { getLog } from '../../redux/slices/monitorsSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

const Monitor = () => {

    const dispatch = useDispatch()
    const { isLoading, log } = useSelector(state => state.monitors)
    useEffect(() => {
        dispatch(getLog())
    }, [dispatch])
    
    const columnsMonitor = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
        },
        {
            title: 'Nome',
            dataIndex: 'Cliente',
            key: 'nome',
            align: 'center',
            render: a => a.nome,
        },
        {
            title: 'Data',
            dataIndex: 'createdAt',
            key: 'data',
            align: 'center',
            render: createdAt => moment(createdAt).format('DD/MM/YYYY')
        },
        {
            title: 'URL',
            dataIndex: 'urlRequest',
            key: 'url',
            align: 'center',  
        },
        {
            title: 'Descrição',
            dataIndex: 'descricao',
            key: 'descricao',
            align: 'center',
            render: description => <span className="monitor-description">{description}</span>
        },
        {
            title: 'Código',
            dataIndex: 'codigo',
            key: 'codigo',
            align: 'center',
        },
        {
            title: 'Admin',
            dataIndex: 'admin',
            key: 'admin',
            align: 'center',
            render: admin => admin ? <Icon className="full-monitor-icon" component={iconSuccess} /> : <Icon className="full-monitor-icon" component={iconError} />
        },
        {
            title: 'Sucesso',
            dataIndex: 'sucesso',
            key: 'sucesso',
            align: 'center',
            render: success => success ? <Icon className="full-monitor-icon" component={iconSuccess} /> : <Icon className="full-monitor-icon" component={iconError} />
        },
    ]

    return (
        <Layout width>
            <Row>
                <Col md={24}>
                    <h1 className="title page-title">Monitor</h1>
                </Col>
                <Col md={24} className="mt-50" id="monitor-table">
                    <Table loading={isLoading} column={columnsMonitor} data={log} id="monitor" />
                </Col>
            </Row>
            
        </Layout>
    )
}

export default Monitor