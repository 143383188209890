import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { request, getInfoLocalStorage } from "../api/config"

const stateFromLocalStorage = getInfoLocalStorage() 

const initialState = {
    isLoading: false,
    isLoadingBalance: false,
    success: false,
    message: null,
    operations: [],
    balance: [],
    isSubmit: false
}

export const getOperations = createAsyncThunk(
    'operations/',
    async (values, thunkAPI) => {
        try {
            const res = await request(`operacional/getOperacoes/${values.type}`)
            if(res.code === 11) {
                return res.operacoes
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao retornar as operações')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao retornar as operações')
        }
    }
)

export const getBalance = createAsyncThunk(
    'operations/balance',
    async (values, thunkAPI) => {
        try {
            const res = await request(`operacional/getSaldo/${values.type}`)
            if(res.code === 51) {
                return res.saldo
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao carregar os saldos')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao carregar os saldos')
        }
    }
)

export const createOperation = createAsyncThunk(
    'operations/create',
    async (values, thunkAPI) => {
        try {
            const id = stateFromLocalStorage.id
            const res = await request(values.type === 'asa' ? 'operacional/updateOperacao' : 'operacional/updateOperacaoSpirit', 'POST', {
                valorSaida: values.output,
                cotacao: values.quota,
                adminId: id
            })
            if (res.code === 51) {
                return res
            } else {
                return thunkAPI.rejectWithValue(res.message)
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao inserir operação')
        }
    }
)

const operationsSlice = createSlice({
    name: 'operations',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getOperations.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getOperations.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true
                state.operations = payload
            })
            .addCase(getOperations.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
            .addCase(getBalance.pending, (state) => {
                state.isLoadingBalance = true
            })
            .addCase(getBalance.fulfilled, (state, { payload }) => {
                state.isLoadingBalance = false
                state.success = true
                state.balance = payload
            })
            .addCase(getBalance.rejected, (state, { payload }) => {
                state.isLoadingBalance = false
                state.success = false
                state.message = payload
            })
            .addCase(createOperation.pending, (state) => {
                state.isSubmit = true
            })
            .addCase(createOperation.fulfilled, (state) => {
                state.isSubmit = false
                state.success = true
            })
            .addCase(createOperation.rejected, (state, { payload }) => {
                state.isSubmit = false
                state.success = false
                state.message = payload
            })
    }
})

const { reducer } = operationsSlice

export default reducer