import React, { useState } from 'react'
import '../../assets/theme/theme.less'
import { LayoutComponent as Layout, TabsMenuComponent as TabsMenu, TitleTabs as Title } from '../../components'
import { Row, Col, Tabs, List, message as alert } from 'antd'
import CardApprovals from './cardApprovals'
import ContributionsTable from './contributionsTable'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getContributions, denyContribution, approveContribution, approveSpiritContribution } from '../../redux/slices/contributionsSlice'

const { TabPane } = Tabs

const ApprovalsContributions = () => {
    const { isLoading, successAction, contributions, isSubmit, success } = useSelector(state => state.contributions)
    const [pending, setPending] = useState([])
    const [quota, setQuota] = useState([])
    const [history, setHistory] = useState([])
    const dispatch = useDispatch()
    const [approved, setApproved] = useState(false)
    const [clicked, setClicked] = useState(0)

    const deny = (reason, idContribution, approved) => {
        setApproved(approved)
        setClicked(idContribution)
        const data = { reason, idContribution }
        dispatch(denyContribution(data)).then((res) => {
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                setTimeout(() => {
                    dispatch(getContributions())
                }, 1000)
            }
        })
    }

    const approve = (quota, idContribution, approved) => {
        setApproved(approved)
        setClicked(idContribution)
        const data = { quota, idContribution }
        dispatch(approveContribution(data)).then((res) => {
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                setTimeout(() => {
                    dispatch(getContributions())
                }, 1000)
            }
        })
    }

    const approveSpirit = (idContribution, idContract, approved) => {
        setApproved(approved)
        setClicked(idContribution)
        const data = { idContribution, idContract }
        dispatch(approveSpiritContribution(data)).then((res) => {
            if(res?.error?.message) {
                alert.error(res.payload)
            } else {
                setTimeout(() => {
                    dispatch(getContributions())
                }, 1000)
            }
        })
    }

    useEffect(() => {
        dispatch(getContributions())
    }, [dispatch])

    useEffect(() => {
        var copy = contributions
        if(contributions && contributions.length > 0) {
            var p = copy.filter(c => c.status == 0)
            var q = copy.filter(c => c.status == 1)
            var h = copy.filter(c => c.status == 2 || c.status == 3) 
            setPending([...p])
            setQuota([...q])
            setHistory([...h])
        }
    }, [contributions])

    return (
        <Layout width>
            <Row>
                <Col md={24}>
                    <h1 className="title page-title">Aprovações</h1>
                </Col>
                <Col md={24} className="mt-15 d-flex align-center">
                    <div className="d-flex align-center m-auto">
                        <TabsMenu type="approvals" />
                    </div>
                </Col>
                <Col md={24} className="mt-15">
                    <Tabs defaultActiveKey="1" centered>
                        <TabPane tab={<Title title="Pendentes" notification={pending.length} />} key="1">
                            <Row className="mt-25">
                                {isLoading ? 'Carregando' : success ?
                                    <List
                                        grid={{ gutter: 16, column: 3}}
                                        dataSource={pending}
                                        pagination
                                        renderItem={item => (
                                            <List.Item>
                                                <CardApprovals success={successAction} clicked={clicked} status="pending" contribution={item} approved={approved} isSubmit={isSubmit} deny={deny} approve={approve} approveSpirit={approveSpirit} />
                                            </List.Item>
                                        )}
                                    /> : 'Houve um erro ao carregar os aportes'
                                }
                            </Row>
                        </TabPane>
                        <TabPane tab={<Title title="Cotização" notification={quota.length} />} key="2">
                            <Row className="mt-25">
                                {isLoading ? 'Carregando' : success ?
                                    <List
                                        grid={{ gutter: 16, column: 3}}
                                        dataSource={quota}
                                        pagination
                                        renderItem={item => (
                                            <List.Item>
                                                <CardApprovals status="quota" success={successAction} clicked={clicked} contribution={item} approved={approved} isSubmit={isSubmit}  approve={approve} />
                                            </List.Item>
                                        )}
                                    /> : 'Houve um erro ao carregar os aportes'
                                }
                            </Row>
                        </TabPane>
                        <TabPane tab={<Title title="Histórico" />} key="3">
                            <Row gutter={[15,15]} className="mt-25">
                                <Col md={24}>
                                    {isLoading ? 'Carregando' : success ?
                                        <ContributionsTable data={history} /> : 'Houve um erro ao carregar os aportes'
                                    }
                                </Col>
                            </Row>
                        </TabPane>
                    </Tabs>
                </Col>
            </Row>
        </Layout>
    )
}

export default ApprovalsContributions