import Icon from '@ant-design/icons';
import { ReactComponent as iconChevronLeft } from '../../assets/img/chevron-left.svg'

const ButtonMenuComponent = (props) => {
    const { children, notification, icon, active } = props

    return (
        <div className={`btn-menu ${active && 'btn-menu-active'}`}>
            <div className="btn-child">
                <Icon component={icon} />
                <label className="btn-text">{children}</label>
            </div>
            <div className="btn-div">
                {notification !== undefined && <div className="btn-notification" notification={notification} >{notification}</div> }
                <Icon component={iconChevronLeft} />
            </div>
        </div>
    )
}

export default ButtonMenuComponent