import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "../api/config"

const initialState = {
    isLoading: false,
    isLoadingNotifications: false,
    success: false,
    message: null,
    log: [],
    notifications: []
}

export const getLog = createAsyncThunk(
    'monitors/',
    async (thunkAPI) => {
        try {
            const res = await request('operacional/getLog')
            if(res.code === 11) {
                return res.log
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao retornar as atividades')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao retornar o monitor')
        }
    }
)

export const getNotifications = createAsyncThunk(
    'monitors/notifications',
    async(thunkAPI) => {
        try {
            const res = await request('operacional/getNotifications')
            if(res.code === 51) {
                return res.notifications
            } else {
                return thunkAPI.rejectWithValue('Houve um erro ao retornar as notificações')
            }
        } catch (e) {
            return thunkAPI.rejectWithValue('Houve um erro ao retornar as notificações')
        }
    }
)

const monitorsSlice = createSlice({
    name: 'monitors',
    initialState,
    extraReducers(builder) {
        builder
            .addCase(getLog.pending, (state) => {
                state.isLoading = true
            })
            .addCase(getLog.fulfilled, (state, { payload }) => {
                state.isLoading = false
                state.success = true
                state.log = payload
            })
            .addCase(getLog.rejected, (state, { payload }) => {
                state.isLoading = false
                state.success = false
                state.message = payload
            })
            .addCase(getNotifications.pending, (state) => {
                state.isLoadingNotifications = true
            })
            .addCase(getNotifications.fulfilled, (state, { payload }) => {
                state.isLoadingNotifications = false
                state.success = true
                state.notifications = payload
            })
            .addCase(getNotifications.rejected, (state, { payload }) => {
                state.isLoadingNotifications = false
                state.success = false
                state.message = payload
            })
    }
})

const { reducer } = monitorsSlice

export default reducer