import { Row, Col, Input } from 'antd'
import moment from 'moment'
import { ButtonComponent as Button } from '../../components'
import { useState } from 'react'
import { maskCurrency } from '../../utils/functions'

const ApproveContract = (props) => {
    const { contract, handleModal, approve, error } = props
    const [quota, setQuota] = useState(0)

    const handleQuota = (e) => {
        setQuota(e.target.value)
    }

    return (
        <>
        <Row>
            <Col md={20} className="d-flex align-center">
                <h1 className="font-bold font-28 tx-white">Aprovar contrato</h1>                
            </Col>
            <Col md={4} className="d-flex align-center justify-end">
                <label className="tx-white btn-close" onClick={handleModal}>Cancelar</label>
            </Col>
        </Row>
        <Row className="deny-info-wrapper d-flex align-items-center">
            <Col md={12} className="border">
                <Row>
                    <Col md={20}>
                        <h3 className="tx-pink font-bold">{contract.Cliente.nome}</h3>
                    </Col>
                    <Col md={4}>
                        <label className="tx-pink font-bold">{contract.tipoContrato === 6 ? 'SPIRIT' : 'ASA'}</label><br />
                        <label className="tx-gray font-16">#{contract.id}</label>
                    </Col>
                    <Col md={24}>
                        <hr />
                    </Col>
                    <Col md={12} className="mt-10">
                        <label className="tx-pink font-bold">Solicitação</label><br />
                        <label className="tx-gray">{moment(contract.createdAt).format("DD/MM/YYYY")}</label>
                    </Col>
                    <Col md={24} className="mt-10">
                        <hr />
                    </Col>
                    <Col md={24} className="mt-10">
                        <label className="tx-pink font-bold">Valor</label><br />
                        <label className="tx-gray font-bold font-22">R$ {maskCurrency(contract.valorInicial)}</label>
                    </Col>
                </Row>
            </Col>
            <Col md={12} className="pl-20">
                <label className="tx-pink font-bold font-16">Valor da cotação</label>
                <Input className="mt-15 bg-white" value={quota} onChange={handleQuota} type='number' />
                {error && <small className='tx-red'>A cotação deve ser maior que 0</small>}
                <Row className="mt-15">
                    <Col md={24}>
                        <Button type="success" active onClick={() => approve(contract.id, quota, contract.valorInicial)}>Aprovar</Button>
                    </Col>
                </Row>
            </Col>
        </Row>
        </>
    )
}

export default ApproveContract