import React from 'react'
import './tabsmenu.less'
import { ButtonTabsComponent as ButtonTab } from '../../components'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { buttonsApprovals, buttonsClient, buttonsWallet } from './menus'
import { useSelector } from 'react-redux'

const TabsMenuComponent = (props) => {
    const { type } = props

    const { isLoadingNotifications, notifications } = useSelector(state => state.monitors)

    const returnNotifications = (type) => {
        if(type === 'aportes') {
            return parseInt(notifications.aportes)
        } else if(type === 'resgates') {
            return parseInt(notifications.resgates)
        } else if(type === 'asa') {
            return parseInt(notifications.asa)
        } else if(type === 'spirit') {
            return parseInt(notifications.spirit)
        } else if(type === 'conversoes' || type === 'geral') {
            return undefined
        } else if(type === 'tokens') {
            return parseInt(notifications.tokens)
        } else if(type === 'pendentes') {
            return parseInt(notifications.clientes)
        }
        else {
            return 0
        }
    }
    
    const components = {
        clients: buttonsClient,
        approvals: buttonsApprovals,
        wallet: buttonsWallet
    }
    
    const location = useLocation()
    const path = location.pathname
    return (
        <div className="tabs-menu-wrapper">
            {components[type].map((i, p) => (
                <Link to={i.link} key={i.title + p}><ButtonTab type="tabs" text={i.title} notification={isLoadingNotifications ? undefined : returnNotifications(i.key)} active={path.includes(i.key) ? true : false} /></Link>
            ))}
        </div>
    )
}

export default TabsMenuComponent