import React from 'react'
import { Card, Row, Col } from 'antd'
import { ButtonComponent as Button } from '../../components'
import { ReactComponent as iconProfile } from '../../assets/img/profile-circle.svg'
import { ReactComponent as iconCheck } from '../../assets/img/check.svg'
import { Link } from 'react-router-dom'
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux'
import Icon from '@ant-design/icons';
import { maskCpf } from '../../utils/functions';

const CardUserAccess = (props) => {

    const { user, clickedCard, confirmAccess } = props
    const { isSubmit, successAction } = useSelector(state => state.users)
    
    return (
        <Card className={`card-access ${successAction && clickedCard === user.id && !isSubmit && 'card-success'}`}>
            {successAction && clickedCard === user.id && !isSubmit ? 
            <div className="d-flex align-center justify-center flex-column">
                <Icon component={iconCheck} />
                <h1 className="tx-white font-bold mt-15 font-28">Acesso liberado</h1>
            </div> :
            <>
                <Row>
                    <Col md={12}>
                        <h4 className="tx-pink card-title">{user.nome}</h4>
                    </Col>
                    <Col md={4}></Col>
                    <Col md={8}>
                        <h4 className="tx-pink card-title">ID</h4>
                        <label className="tx-gray">#{user.id}</label>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={12}>
                        <label className="tx-pink font-bold">CPF</label><br />
                        <label className="tx-gray">{maskCpf(user.cpf)}</label>
                    </Col>
                    <Col md={12}>
                        <label className="tx-pink font-bold">Filiação</label><br />
                        <label className="tx-gray">{user.nomeMae}</label>
                    </Col>
                </Row>
                <hr />
                <Link to={`/cliente/${user.id}`}><Button icon={iconProfile} type="primary" className="mt-25">Ver perfil</Button></Link>
                <Row className="mt-15">
                    {/* <Col md={12} className="pr-5">
                        <Button type="danger">Reprovar</Button>
                    </Col> */}
                    <Col md={24} className="pl-5">
                        <Button disabled={isSubmit} onClick={() => confirmAccess(user.id)} type="success">{isSubmit && clickedCard === user.id ? <LoadingOutlined /> : 'Aprovar'}</Button>
                    </Col>
                </Row>
            </>}
        </Card>
    )
}

export default CardUserAccess