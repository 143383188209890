import React from 'react'
import { Card, Row, Col } from 'antd'
import { maskCpf, maskCurrency } from '../../utils/functions'
import moment from 'moment'
import { ButtonComponent as Button } from '../../components'
import { LoadingOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { ReactComponent as iconError } from '../../assets/img/error.svg'
import { ReactComponent as iconCheck } from '../../assets/img/check.svg'

const CardTokens = (props) => {
    const { token, isSubmit, clicked, approved, success, onClick } = props
    
    return (
        <>
            <Card className={success && !isSubmit && clicked === token.id ? approved ? 'card-success' : 'card-reproved' : '' }>
                {success && clicked === token.id && !isSubmit ? approved ?
                    <div className="d-flex align-center justify-center flex-column">
                        <Icon component={iconCheck} />
                        <h1 className="tx-white font-bold mt-15 font-22 text-center">Token aprovado</h1>
                    </div> :
                    <div className="d-flex align-center justify-center flex-column">
                        <Icon component={iconError} />
                        <h1 className="tx-white font-bold mt-15 font-22 text-center">Token reprovado</h1>
                    </div> :
                <>
                <Row>
                    <Col md={24}>
                        <h4 className="tx-pink card-title font-16">{token.Cliente.nome}</h4>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={12}>
                        <label className="tx-pink font-bold">CPF</label><br />
                        <label className="tx-gray">{maskCpf(token.Cliente.cpf)}</label>
                    </Col>
                    <Col md={12}>
                        <label className="tx-pink font-bold">Data compra</label><br />
                        <label className="tx-gray">{moment(token.createdAt).format("DD/MM/YYYY HH:mm")}</label>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col md={12}>
                        <label className="tx-pink font-bold">Tokens</label><br />
                        <label className="tx-gray">{token.qtTokens}</label>
                    </Col>
                    <Col md={12}>
                        <label className="tx-pink font-bold">Valor</label><br />
                        <label className="tx-gray">R${maskCurrency(token.qtTokens*500)}</label>
                    </Col>
                    <Col md={24} className="mt-10">
                        <label className="tx-pink font-bold">Endereço Solana</label><br />
                        <label className="tx-gray word-break">{token.enderecoSolana}</label>
                    </Col>
                </Row>
                <hr />
                <Row gutter={15}>
                    <Col md={12}>
                        <Button onClick={() => onClick(token.id, true, token.idCliente, 1)} type="success" disabled={isSubmit}>{isSubmit && approved ? <LoadingOutlined /> : 'Aprovar'}</Button>
                    </Col>
                    <Col md={12}>
                        <Button onClick={() => onClick(token.id, false, token.idCliente, 2)} type="danger" disabled={isSubmit}>{isSubmit && !approved ? <LoadingOutlined /> : 'Reprovar'}</Button>
                    </Col>
                </Row>
                </>}
            </Card>
        </>
    )
}

export default CardTokens