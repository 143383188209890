import React, { useState } from 'react'
import { Card, Row, Col, Modal } from 'antd'
import { ButtonComponent as Button } from '../../components'
import { ReactComponent as iconDownload } from '../../assets/img/download.svg'
import { ReactComponent as iconError } from '../../assets/img/error.svg'
import { ReactComponent as iconCheck } from '../../assets/img/check.svg'
import moment from 'moment'
import { downloadFile } from '../../utils/functions'
import DenyContribution from './denyContribution'
import ApproveContribution from './approveContribution'
import { LoadingOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { maskCurrency } from '../../utils/functions'

const CardApprovals = (props) => {
    const [openModal, setOpenModal] = useState(false)
    const [openModalApproval, setOpenModalApproval] = useState(false)
    const { status, contribution, approved, deny, isSubmit, clicked, success, approve, approveSpirit } = props
    const handleModal = () => {
        const aux = openModal
        setOpenModal(!aux)
    }

    const handleModalApprove = () => {
        const aux = openModalApproval
        setOpenModalApproval(!aux)
    }

    return (
        <>
        <Card className={`${status === 'quota' ? 'card-quota' : 'card-contribution'} ${success && !isSubmit && clicked === contribution.id ? approved ? 'card-success' : 'card-reproved' : '' }`}>
            {success && clicked === contribution.id && !isSubmit ? approved ?
                <div className="d-flex align-center justify-center flex-column">
                    <Icon component={iconCheck} />
                    <h1 className="tx-white font-bold mt-15 font-22 text-center">Aporte {status === 'pending'? 'aprovado' : 'cotizado'}</h1>
                </div> :
                <div className="d-flex align-center justify-center flex-column">
                    <Icon component={iconError} />
                    <h1 className="tx-white font-bold mt-15 font-22 text-center">Aporte reprovado</h1>
                </div> :
            <>
            <Row>
                <Col md={12}>
                    <h4 className="tx-pink card-title font-16">{contribution.Contrato.Cliente.nome}</h4>
                </Col>
                <Col md={4}></Col>
                <Col md={8}>
                    <h4 className="tx-pink card-title">{contribution.Contrato.tipoContrato === 6 ? 'SPIRIT' : 'ASA'}</h4>
                    <label className="tx-gray">#{contribution.Contrato.id}</label>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col md={12}>
                    <label className="tx-pink font-bold">Solicitação</label><br />
                    <label className="tx-gray">{moment(contribution.createdAt).format('DD/MM/YYYY HH:mm')}</label>
                </Col>
                {status === 'quota' &&
                <Col md={12}>
                    <label className="tx-pink font-bold">Aprovação</label><br />
                    <label className="tx-gray">{moment(contribution.dataAprovacao).format('DD/MM/YYYY HH:mm')}</label>
                </Col>}
            </Row>
            <hr />
            <Row>
                <Col md={24}>
                    <label className="tx-pink font-bold">Valor</label><br />
                    <label className="tx-gray font-22 font-bold">R${maskCurrency(contribution.valor)}</label>
                </Col>
            </Row>
            {status === 'pending' ?
                <Row className="mt-15">
                    <Col md={24}>
                        <Button type="primary" icon={iconDownload} active onClick={() => downloadFile(contribution.comprovante)}>Download comprovante</Button>
                    </Col>
                    <Col md={12} className="pr-5 mt-15">
                        <Button type="danger" onClick={handleModal} disabled={isSubmit && clicked === contribution.id ? true : false}>{isSubmit && !approved && clicked === contribution.id ? <LoadingOutlined /> : "Reprovar"}</Button>
                    </Col>
                    <Col md={12} className="pl-5 mt-15">
                        <Button type="success" disabled={isSubmit && clicked === contribution.id ? true : false} onClick={contribution.Contrato.tipoContrato === 6 ? () => approveSpirit(contribution.id, contribution.Contrato.id, true) : handleModalApprove}>{isSubmit && approved && clicked === contribution.id ? <LoadingOutlined /> : "Aprovar"}</Button>
                    </Col>
                </Row> :
                <Row className="mt-15">
                    <Col md={24}>
                        <Button type="primary" active onClick={handleModalApprove} disabled={isSubmit && clicked === contribution.id ? true : false}>{isSubmit && approved && clicked === contribution.id ? <LoadingOutlined /> : "Inserir Cotação"}</Button>
                    </Col>
                </Row>
            }
            </>}
        </Card>
        <Modal
            visible={openModal}
            centered
            footer={null}
            closable={false}
            maskClosable={false}
            className="modal-deny"
            width="70vw"
        >
            <DenyContribution contribution={contribution} handleModal={handleModal} deny={deny} />
        </Modal>
        <Modal
            visible={openModalApproval}
            centered
            footer={null}
            closable={false}
            maskClosable={false}
            className="modal-deny"
            width="70vw"
        >
            <ApproveContribution contribution={contribution} handleModalApprove={handleModalApprove} approve={approve} />
        </Modal>
        </>
    )
}

export default CardApprovals